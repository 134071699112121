import React, { useState, useEffect } from 'react';
import { getFirestore, collection, getDocs } from 'firebase/firestore';
import { app } from '../../config/firebase'; // Adjust the path to your Firebase config
import { TailSpin } from 'react-loader-spinner'; // Import the spinner
import Modal from 'react-modal'; // Import react-modal
Modal.setAppElement('#root'); // Set the app element for accessibility

const HealthCard = () => {
  const [healthCards, setHealthCards] = useState([]);
  const [loading, setLoading] = useState(true); // State to manage loading
  const [searchNumber, setSearchNumber] = useState(''); // State for the search input
  const [filteredCard, setFilteredCard] = useState(null); // State for the filtered health card
  const [modalIsOpen, setModalIsOpen] = useState(false); // State for modal visibility

  useEffect(() => {
    const fetchHealthCards = async () => {
      const db = getFirestore(app);
      const healthCardCollection = collection(db, 'HealthCardEntry');
      const healthCardSnapshot = await getDocs(healthCardCollection);
      const healthCardList = healthCardSnapshot.docs.map(doc => ({
        id: doc.id, // Document ID
        ...doc.data(), // Spread all fields from the document data
      }));
      setHealthCards(healthCardList);
      setLoading(false); // Set loading to false after data is fetched
    };

    fetchHealthCards();
  }, []);

  const handleSearch = () => {
    const foundCard = healthCards.find(card => card.Number === searchNumber);
    if (foundCard) {
      setFilteredCard(foundCard);
      setModalIsOpen(true);
    } else {
      alert('No health card entry found with this number');
    }
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <TailSpin 
          height="80" 
          width="80" 
          color="blue" 
          ariaLabel="loading-indicator"
        />
      </div>
    );
  }

  return (
    <div className="container mx-auto mt-8">
      <h2 className="text-2xl font-bold mb-4">
        Total Health Card Entries: {healthCards.length}
      </h2>

      {/* Search Field */}
      <div className="mb-4">
        <input 
          type="text" 
          placeholder="Enter Mobile Number" 
          value={searchNumber} 
          onChange={(e) => setSearchNumber(e.target.value)} 
          className="border px-4 py-2"
        />
        <button 
          onClick={handleSearch} 
          className="ml-2 px-4 py-2 bg-blue-500 text-white rounded"
        >
          Search
        </button>
      </div>

      <div className="overflow-x-auto">
        <table className="min-w-full bg-white border border-gray-200">
          <thead>
            <tr>
              <th className="px-4 py-2 border-b">ID</th>
              <th className="px-4 py-2 border-b">Name</th>
              <th className="px-4 py-2 border-b">Number</th>
              <th className="px-4 py-2 border-b">Agent</th>
              <th className="px-4 py-2 border-b">Date</th>
            </tr>
          </thead>
          <tbody>
            {healthCards.length > 0 ? (
              healthCards.map((card) => (
                <tr key={card.id}>
                  <td className="px-4 py-2 border-b">{card.id}</td>
                  <td className="px-4 py-2 border-b">{card.Name}</td>
                  <td className="px-4 py-2 border-b">{card.Number}</td>
                  <td className="px-4 py-2 border-b">{card.Agent}</td>
                  <td className="px-4 py-2 border-b">{card.Date}</td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="5" className="text-center py-4">
                  No health card entries found
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>

      {/* Modal for Displaying the Filtered Health Card */}
      {filteredCard && (
        <Modal
          isOpen={modalIsOpen}
          onRequestClose={() => setModalIsOpen(false)}
          contentLabel="Health Card Details"
        >
          <h2 className="text-xl font-bold mb-4">Health Card Details</h2>
          <p><strong>ID:</strong> {filteredCard.id}</p>
          <p><strong>Date:</strong> {filteredCard.Date}</p>
          <p><strong>Name:</strong> {filteredCard.Name}</p>
          <p><strong>Number:</strong> {filteredCard.Number}</p>
          <p><strong>Address:</strong> {filteredCard.Address}</p>
          <p><strong>Member1:</strong> {filteredCard.Member1}</p>
          <p><strong>Member2:</strong> {filteredCard.Member2}</p>
          <p><strong>Member3:</strong> {filteredCard.Member3}</p>
          <p><strong>Agent:</strong> {filteredCard.Agent}</p>
          <button 
            onClick={() => setModalIsOpen(false)} 
            className="mt-4 px-4 py-2 bg-red-500 text-white rounded"
          >
            Close
          </button>
        </Modal>
      )}
    </div>
  );
};

export default HealthCard;
