import React, { useState } from 'react';
import { getDatabase, ref, set } from "firebase/database";
import { auth } from '../config/firebase'; // Adjust the path if necessary
import { useNavigate } from 'react-router-dom'; // For redirection

const Register = () => {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [mobileNumber, setMobileNumber] = useState('');
  const navigate = useNavigate(); // Initialize navigate

  // Function to get the current timestamp in DD MM YYYY HH MM SS format
  const getCurrentTimestamp = () => {
    const now = new Date();
    const day = String(now.getDate()).padStart(2, '0');
    const month = String(now.getMonth() + 1).padStart(2, '0'); // Months are zero-based
    const year = now.getFullYear();
    const hours = String(now.getHours()).padStart(2, '0');
    const minutes = String(now.getMinutes()).padStart(2, '0');
    const seconds = String(now.getSeconds()).padStart(2, '0');

    return `${day} ${month} ${year} ${hours} ${minutes} ${seconds}`;
  };

  const handleRegister = () => {
    const db = getDatabase();
    const user = auth.currentUser;

    if (user) {
      const userProfileRef = ref(db, 'UserProfile/' + user.uid);

      set(userProfileRef, {
        firstName,
        lastName,
        mobileNumber,
        role: "user", // Add the role field
        timestamp: getCurrentTimestamp() // Add the timestamp field
      }).then(() => {
        console.log('User profile saved successfully!');
        navigate('/'); // Redirect to the main page
      }).catch((error) => {
        console.error('Error saving user profile:', error);
      });
    } else {
      console.error('No user is logged in');
    }
  };

  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-100">
      <div className="bg-white p-8 rounded-lg shadow-md w-full max-w-md">
        <h2 className="text-2xl font-bold text-center">Register</h2>
        <form className="mt-4">
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2">First Name</label>
            <input 
              type="text" 
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" 
              placeholder="First Name"
            />
          </div>
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2">Last Name</label>
            <input 
              type="text" 
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" 
              placeholder="Last Name"
            />
          </div>
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2">Mobile Number</label>
            <input 
              type="text" 
              value={mobileNumber}
              onChange={(e) => setMobileNumber(e.target.value)}
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" 
              placeholder="Mobile Number"
            />
          </div>
          <div className="flex items-center justify-between">
            <button 
              type="button" 
              onClick={handleRegister}
              className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
            >
              Confirm
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Register;
