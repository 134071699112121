import React from 'react';

const UserProfilePopup = ({ userProfile, onClose }) => {
  if (!userProfile) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
      <div className="bg-white p-8 rounded shadow-md w-96">
        <h2 className="text-xl font-bold mb-4">User Profile</h2>
        <p><strong>First Name:</strong> {userProfile.firstName}</p>
        <p><strong>Last Name:</strong> {userProfile.lastName}</p>
        <p><strong>Mobile Number:</strong> {userProfile.mobileNumber}</p>
        <p><strong>Role:</strong> {userProfile.role}</p>
        <p><strong>WhatsApp Message Count:</strong> {userProfile.whatsappMessageCount}</p>
        <p><strong>Last WhatsApp Message Timestamp:</strong> {new Date(userProfile.lastWhatsAppMessageTimestamp).toLocaleString()}</p>
        <p><strong>Timestamp:</strong> {new Date(userProfile.timestamp).toLocaleString()}</p>
        <button
          className="mt-4 w-full bg-blue-500 text-white p-2 rounded hover:bg-blue-600 transition duration-200"
          onClick={onClose}
        >
          Close
        </button>
      </div>
    </div>
  );
};

export default UserProfilePopup;
