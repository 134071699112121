import React, { useEffect, useState } from 'react';
import { getDatabase, ref, get, update } from 'firebase/database';
import { useParams } from 'react-router-dom';

const DoctorDetails = () => {
  const { id } = useParams();
  const [doctor, setDoctor] = useState(null);

  useEffect(() => {
    const fetchDoctor = async () => {
      const db = getDatabase();
      const doctorRef = ref(db, `DoctorProfile/${id}`);
      try {
        const snapshot = await get(doctorRef);
        if (snapshot.exists()) {
          setDoctor(snapshot.val());
        } else {
          console.log('No data available');
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchDoctor();
  }, [id]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setDoctor({ ...doctor, [name]: value });
  };

  const handleSave = async () => {
    const db = getDatabase();
    const doctorRef = ref(db, `DoctorProfile/${id}`);
    try {
      await update(doctorRef, doctor);
      alert('Doctor profile updated successfully!');
    } catch (error) {
      console.error('Error updating data:', error);
    }
  };

  if (!doctor) {
    return <div>Loading...</div>;
  }

  return (
    <div className="container mx-auto mt-8">
      <h2 className="text-2xl font-bold mb-4">Doctor Details</h2>
      <div className="grid grid-cols-2 gap-4">
        <div>
          <label className="block mb-2">First Name</label>
          <input 
            type="text" 
            name="firstName"
            value={doctor.firstName || ''}
            onChange={handleInputChange}
            className="border p-2 w-full"
          />
        </div>
        <div>
          <label className="block mb-2">Last Name</label>
          <input 
            type="text" 
            name="lastName"
            value={doctor.lastName || ''}
            onChange={handleInputChange}
            className="border p-2 w-full"
          />
        </div>
        <div>
          <label className="block mb-2">Avatar</label>
          {doctor.avatar && <img src={doctor.avatar} alt="Avatar" className="mb-2 w-32 h-32 object-cover" />}
          <input 
            type="text" 
            name="avatar"
            value={doctor.avatar || ''}
            onChange={handleInputChange}
            className="border p-2 w-full"
          />
        </div>

        <div>
          <label className="block mb-2">Degree Image</label>
          {doctor.degreeImg && <img src={doctor.degreeImg} alt="Degree" className="mb-2 w-32 h-32 object-cover" />}
          <input 
            type="text" 
            name="degreeImg"
            value={doctor.degreeImg || ''}
            onChange={handleInputChange}
            className="border p-2 w-full"
          />
        </div>

        <div>
          <label className="block mb-2">ID Proof Image</label>
          {doctor.idproofimg && <img src={doctor.idproofimg} alt="ID Proof" className="mb-2 w-32 h-32 object-cover" />}
          <input 
            type="text" 
            name="idproofimg"
            value={doctor.idproofimg || ''}
            onChange={handleInputChange}
            className="border p-2 w-full"
          />
        </div>

        <div>
          <label className="block mb-2">Registration Image</label>
          {doctor.regImg && <img src={doctor.regImg} alt="Registration" className="mb-2 w-32 h-32 object-cover" />}
          <input 
            type="text" 
            name="regImg"
            value={doctor.regImg || ''}
            onChange={handleInputChange}
            className="border p-2 w-full"
          />
        </div>


        <div>
          <label className="block mb-2">City</label>
          <input 
            type="text" 
            name="city"
            value={doctor.city || ''}
            onChange={handleInputChange}
            className="border p-2 w-full"
          />
        </div>
        <div>
          <label className="block mb-2">Degree</label>
          <input 
            type="text" 
            name="degree"
            value={doctor.degree || ''}
            onChange={handleInputChange}
            className="border p-2 w-full"
          />
        </div>
       
        <div>
          <label className="block mb-2">Experience</label>
          <input 
            type="text" 
            name="experience"
            value={doctor.experience || ''}
            onChange={handleInputChange}
            className="border p-2 w-full"
          />
        </div>
        <div>
          <label className="block mb-2">Gender</label>
          <input 
            type="text" 
            name="gender"
            value={doctor.gender || ''}
            onChange={handleInputChange}
            className="border p-2 w-full"
          />
        </div>
        <div>
          <label className="block mb-2">ID Proof</label>
          <input 
            type="text" 
            name="idProof"
            value={doctor.idProof || ''}
            onChange={handleInputChange}
            className="border p-2 w-full"
          />
        </div>
        <div>
          <label className="block mb-2">ID Number</label>
          <input 
            type="text" 
            name="idnumber"
            value={doctor.idnumber || ''}
            onChange={handleInputChange}
            className="border p-2 w-full"
          />
        </div>
       
        <div>
          <label className="block mb-2">Phone Number</label>
          <input 
            type="text" 
            name="phoneNumber"
            value={doctor.phoneNumber || ''}
            onChange={handleInputChange}
            className="border p-2 w-full"
          />
        </div>
        <div>
          <label className="block mb-2">Rating</label>
          <input 
            type="number" 
            name="rating"
            value={doctor.rating || 0}
            onChange={handleInputChange}
            className="border p-2 w-full"
          />
        </div>
        <div>
          <label className="block mb-2">Registration Number</label>
          <input 
            type="text" 
            name="reg_number"
            value={doctor.reg_number || ''}
            onChange={handleInputChange}
            className="border p-2 w-full"
          />
        </div>
       
        <div>
          <label className="block mb-2">Specialization</label>
          <input 
            type="text" 
            name="spname"
            value={doctor.spname || ''}
            onChange={handleInputChange}
            className="border p-2 w-full"
          />
        </div>
        <div>
          <label className="block mb-2">State</label>
          <input 
            type="text" 
            name="state"
            value={doctor.state || ''}
            onChange={handleInputChange}
            className="border p-2 w-full"
          />
        </div>
        <div>
          <label className="block mb-2">Status</label>
          <select 
            name="status" 
            value={doctor.status || 'Hold'} 
            onChange={handleInputChange}
            className="border p-2 w-full"
          >
            <option value="Live">Live</option>
            <option value="Hold">Hold</option>
            <option value="In Review">In Review</option>
          </select>
        </div>
        <div>
          <label className="block mb-2">Timestamp</label>
          <input 
            type="text" 
            name="timestamp"
            value={doctor.timestamp || ''}
            readOnly
            className="border p-2 w-full bg-gray-100"
          />
        </div>
      </div>
      <button 
        onClick={handleSave} 
        className="mt-4 bg-blue-500 text-white py-2 px-4 rounded"
      >
        Save
      </button>
    </div>
  );
};

export default DoctorDetails;
