import React, { useEffect, useState } from 'react';
import { db, storage } from '../../config/firebase'; // Adjust the import path based on your project structure
import { collection, getDocs, addDoc } from 'firebase/firestore';
import { ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage';

const LabTest = () => {
  const [labTests, setLabTests] = useState([]);
  const [showAddForm, setShowAddForm] = useState(false);
  const [formData, setFormData] = useState({
    testName: '',
    testType: '',
    testPrice: '',
    testDays: '',
    testDescription: '',
    testImage: null,
  });
  const [uploadProgress, setUploadProgress] = useState(0);

  // Fetch LabTests data from Firestore
  const fetchLabTests = async () => {
    try {
      const querySnapshot = await getDocs(collection(db, 'LabTests'));
      const testsData = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setLabTests(testsData);
    } catch (error) {
      console.error('Error fetching lab tests:', error);
    }
  };

  useEffect(() => {
    fetchLabTests();
  }, []);

  // Handle form input changes
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  // Handle file input change
  const handleFileChange = (e) => {
    setFormData({ ...formData, testImage: e.target.files[0] });
  };

  // Handle form submission for adding a lab test
  const handleAddSubmit = async (e) => {
    e.preventDefault();

    if (formData.testImage) {
      const storageRef = ref(storage, `labTests/${formData.testImage.name}`);
      const uploadTask = uploadBytesResumable(storageRef, formData.testImage);

      uploadTask.on(
        'state_changed',
        (snapshot) => {
          const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          setUploadProgress(progress);
        },
        (error) => {
          console.error('Image upload failed:', error);
        },
        async () => {
          const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
          try {
            await addDoc(collection(db, 'LabTests'), {
              ...formData,
              testPrice: parseFloat(formData.testPrice),
              testImage: downloadURL,
            });
            setShowAddForm(false);
            setUploadProgress(0);
            setFormData({
              testName: '',
              testType: '',
              testPrice: '',
              testDays: '',
              testDescription: '',
              testImage: null,
            });
            fetchLabTests(); // Refresh lab tests list after adding new test
          } catch (error) {
            console.error('Error adding lab test:', error);
          }
        }
      );
    }
  };

  return (
    <div className="p-6">
      <h1 className="text-2xl font-bold mb-4">Lab Tests</h1>

      <div className="flex gap-4 mb-6">
        <button
          onClick={() => setShowAddForm(true)}
          className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
        >
          Add
        </button>
        {/* Other buttons (Edit, Update, Delete) */}
      </div>

      {showAddForm && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-white p-6 rounded shadow-lg w-96">
            <h2 className="text-lg font-bold mb-4">Add Lab Test</h2>
            <form onSubmit={handleAddSubmit}>
              <input
                type="text"
                name="testName"
                placeholder="Test Name"
                value={formData.testName}
                onChange={handleChange}
                className="w-full p-2 mb-3 border rounded"
                required
              />
              <input
                type="text"
                name="testType"
                placeholder="Test Type"
                value={formData.testType}
                onChange={handleChange}
                className="w-full p-2 mb-3 border rounded"
                required
              />
              <input
                type="text"
                name="testPrice"
                placeholder="Test Price"
                value={formData.testPrice}
                onChange={handleChange}
                className="w-full p-2 mb-3 border rounded"
                required
              />
              <input
                type="text"
                name="testDays"
                placeholder="Test Days"
                value={formData.testDays}
                onChange={handleChange}
                className="w-full p-2 mb-3 border rounded"
                required
              />
              <textarea
                name="testDescription"
                placeholder="Test Description"
                value={formData.testDescription}
                onChange={handleChange}
                className="w-full p-2 mb-3 border rounded"
                required
              />
              <input
                type="file"
                onChange={handleFileChange}
                className="w-full p-2 mb-3 border rounded"
                required
              />
              {uploadProgress > 0 && (
                <div className="w-full bg-gray-200 rounded-full h-2.5 mb-3">
                  <div
                    className="bg-blue-500 h-2.5 rounded-full"
                    style={{ width: `${uploadProgress}%` }}
                  ></div>
                </div>
              )}
              <div className="flex justify-end gap-4">
                <button
                  type="button"
                  onClick={() => setShowAddForm(false)}
                  className="bg-gray-500 text-white px-4 py-2 rounded hover:bg-gray-600"
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
                >
                  Save
                </button>
              </div>
            </form>
          </div>
        </div>
      )}

      <div className="overflow-x-auto">
        <table className="min-w-full bg-white border border-gray-200 shadow-md rounded">
          <thead>
            <tr className="bg-gray-100 text-left text-sm uppercase text-gray-600">
              <th className="py-3 px-4">Test Name</th>
              <th className="py-3 px-4">Test Type</th>
              <th className="py-3 px-4">Test Price</th>
              <th className="py-3 px-4">Test Days</th>
              <th className="py-3 px-4">Test Description</th>
              <th className="py-3 px-4">Test Image</th>
            </tr>
          </thead>
          <tbody>
            {labTests.map((test) => (
              <tr key={test.id} className="border-b border-gray-200">
                <td className="py-2 px-4">{test.testName}</td>
                <td className="py-2 px-4">{test.testType}</td>
                <td className="py-2 px-4">{test.testPrice}</td>
                <td className="py-2 px-4">{test.testDays}</td>
                <td className="py-2 px-4">{test.testDescription}</td>
                <td className="py-2 px-4">
                  {test.testImage ? (
                    <img src={test.testImage} alt={test.testName} className="h-16 w-16 object-cover" />
                  ) : (
                    'No Image'
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default LabTest;
