// Profile.js
import React, { useState, useEffect } from 'react';
import { db } from '../../config/firebase'; // Adjust the import path if needed
import { collection, getDocs } from 'firebase/firestore';

const Profile = () => {
  const [isMedicalPractitioner, setIsMedicalPractitioner] = useState(true);
  const [isClinicOwner, setIsClinicOwner] = useState(false);
  const [fieldOfStudy, setFieldOfStudy] = useState('');
  const [fieldsOfStudy, setFieldsOfStudy] = useState([]);

  // Fetch fields of study from Firestore
  useEffect(() => {
    const fetchFieldsOfStudy = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, 'DoctorSpeciality'));
        const fields = querySnapshot.docs.map(doc => doc.data().spname);
        setFieldsOfStudy(fields);
      } catch (error) {
        console.error('Error fetching fields of study:', error);
      }
    };

    fetchFieldsOfStudy();
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log({
      isMedicalPractitioner,
      isClinicOwner,
      fieldOfStudy,
    });
  };

  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-100">
      <form
        className="bg-white p-8 rounded-lg shadow-lg w-full max-w-md"
        onSubmit={handleSubmit}
      >
        <h2 className="text-center text-2xl font-semibold mb-4">
          Manage your presence on Nidaan
        </h2>
        <p className="text-center mb-6">
          Choose your profile. If you are a practitioner and also own a clinic, choose both options.
        </p>

        <div className="flex items-center mb-4">
          <input
            type="checkbox"
            id="medicalPractitioner"
            checked={isMedicalPractitioner}
            onChange={() => setIsMedicalPractitioner(!isMedicalPractitioner)}
            className="mr-2"
          />
          <label htmlFor="medicalPractitioner" className="text-sm">
            I am a medical practitioner
          </label>
        </div>

        <div className="flex items-center mb-4">
          <input
            type="checkbox"
            id="clinicOwner"
            checked={isClinicOwner}
            onChange={() => setIsClinicOwner(!isClinicOwner)}
            className="mr-2"
          />
          <label htmlFor="clinicOwner" className="text-sm">
            I am a clinic owner
          </label>
        </div>

        <div className="mb-6">
          <label htmlFor="fieldOfStudy" className="block mb-2 text-sm">
            Field of Study
          </label>
          <select
            id="fieldOfStudy"
            value={fieldOfStudy}
            onChange={(e) => setFieldOfStudy(e.target.value)}
            className="w-full px-3 py-2 border rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
          >
            <option value="">Choose your field of study</option>
            {fieldsOfStudy.map((field, index) => (
              <option key={index} value={field}>
                {field}
              </option>
            ))}
          </select>
        </div>

        <button
          type="submit"
          className="w-full bg-orange-500 text-white py-2 rounded hover:bg-orange-600 transition-colors"
        >
          Next
        </button>

        <div className="text-center mt-4 text-sm">
          <a href="/" className="text-blue-500 hover:underline">
            I don't want a profile on Nidaan, skip now
          </a>
        </div>
      </form>
    </div>
  );
};

export default Profile;
