import React from 'react'

const UserDashboard = () => {
  return (
    <div>
      User
    </div>
  )
}

export default UserDashboard
