import React from 'react'

const DoctorAdminMain = () => {
  return (
    <div>
      MAin Doc Admin
    </div>
  )
}

export default DoctorAdminMain
