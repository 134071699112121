// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth, GoogleAuthProvider } from "firebase/auth";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";
import { getDatabase } from "firebase/database"; // Import Realtime Database
import { getMessaging } from 'firebase/messaging';
import { getStorage } from 'firebase/storage';


// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyBn4s-PfafR-BmahM16rzeGAFuHQ2lscyQ",
  authDomain: "nidaan-admin.firebaseapp.com",
  databaseURL: "https://nidaan-admin-default-rtdb.firebaseio.com", // Ensure this URL is correct
  projectId: "nidaan-admin",
  storageBucket: "nidaan-admin.appspot.com",
  messagingSenderId: "204400992300",
  appId: "1:204400992300:web:c58fddccbd219b4fa5f189",
  measurementId: "G-36DZ50TEB9"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const messaging = getMessaging(app);


// Initialize Firebase Authentication and get a reference to the service
const auth = getAuth(app);
const provider = new GoogleAuthProvider();
const db = getFirestore(app);
const database = getDatabase(app); // Initialize Realtime Database
const storage = getStorage(app); // Initialize Firebase Storage


export {messaging, app, auth, provider, db, database ,storage};
