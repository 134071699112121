import React, { useEffect, useState } from 'react';
import { getFirestore, collection, getDocs, addDoc, deleteDoc, doc } from 'firebase/firestore';
import { getDatabase, ref, get } from 'firebase/database';
import { app } from '../../config/firebase'; // Adjust the import according to your project structure
import UserProfilePopup from '../../components/UserProfilePopup'; // Adjust the import according to your project structure

const Enquiry = () => {
  const [appointments, setAppointments] = useState([]);
  const [selectedUserProfile, setSelectedUserProfile] = useState(null);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [newEnquiry, setNewEnquiry] = useState({
    name: '',
    city: '',
    contactNumber: '',
    problem: '',
    userId: '',
    timestamp: new Date(),
  });

  useEffect(() => {
    fetchAppointments();
  }, []);

  const fetchAppointments = async () => {
    const db = getFirestore(app);
    const appointmentsCollection = collection(db, 'appointments');
    const appointmentsSnapshot = await getDocs(appointmentsCollection);
    const appointmentsList = appointmentsSnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));
    setAppointments(appointmentsList);
  };

  const handleUserIdClick = async (userId) => {
    const db = getDatabase(app);
    const userProfileRef = ref(db, `UserProfile/${userId}`);
    const userProfileSnapshot = await get(userProfileRef);

    if (userProfileSnapshot.exists()) {
      setSelectedUserProfile(userProfileSnapshot.val());
      setIsPopupOpen(true);
    } else {
      console.log('No data available for this user');
    }
  };

  const handleClosePopup = () => {
    setIsPopupOpen(false);
    setSelectedUserProfile(null);
  };

  const handleAddEnquiry = async (e) => {
    e.preventDefault();
    const db = getFirestore(app);
    try {
      await addDoc(collection(db, 'appointments'), {
        ...newEnquiry,
        timestamp: new Date(),
      });
      setNewEnquiry({
        name: '',
        city: '',
        contactNumber: '',
        problem: '',
        userId: '',
        timestamp: new Date(),
      });
      setIsModalOpen(false);
      fetchAppointments(); // Refresh appointments after adding a new one
      console.log('New enquiry added successfully');
    } catch (error) {
      console.error('Error adding new enquiry: ', error);
    }
  };

  const handleDeleteEnquiry = async (id) => {
    const db = getFirestore(app);
    try {
      await deleteDoc(doc(db, 'appointments', id));
      setAppointments(appointments.filter((appointment) => appointment.id !== id)); // Update state to remove the deleted appointment
      console.log('Enquiry deleted successfully');
    } catch (error) {
      console.error('Error deleting enquiry: ', error);
    }
  };

  return (
    <div className="container mx-auto p-4">
      <h1 className="text-2xl font-bold mb-4">Enquiry</h1>
      <button
        className="mb-4 px-4 py-2 bg-blue-500 text-white rounded"
        onClick={() => setIsModalOpen(true)}
      >
        Add Enquiry
      </button>

      <table className="min-w-full bg-white">
        <thead>
          <tr>
            <th className="py-2 px-4 border-b">Document ID</th>
            <th className="py-2 px-4 border-b">Name</th>
            <th className="py-2 px-4 border-b">City</th>
            <th className="py-2 px-4 border-b">Contact Number</th>
            <th className="py-2 px-4 border-b">Problem</th>
            <th className="py-2 px-4 border-b">Timestamp</th>
            <th className="py-2 px-4 border-b">User ID</th>
            <th className="py-2 px-4 border-b">Actions</th>
          </tr>
        </thead>
        <tbody>
          {appointments.map((appointment) => (
            <tr key={appointment.id}>
              <td className="py-2 px-4 border-b">{appointment.id}</td>
              <td className="py-2 px-4 border-b">{appointment.name}</td>
              <td className="py-2 px-4 border-b">{appointment.city}</td>
              <td className="py-2 px-4 border-b">{appointment.contactNumber}</td>
              <td className="py-2 px-4 border-b">{appointment.problem}</td>
              <td className="py-2 px-4 border-b">
                {new Date(appointment.timestamp?.toDate()).toLocaleString()}
              </td>
              <td
                className="py-2 px-4 border-b text-blue-500 cursor-pointer"
                onClick={() => handleUserIdClick(appointment.userId)}
              >
                {appointment.userId}
              </td>
              <td className="py-2 px-4 border-b">
                <button
                  className="px-2 py-1 bg-red-500 text-white rounded"
                  onClick={() => handleDeleteEnquiry(appointment.id)}
                >
                  Delete
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      {isPopupOpen && (
        <UserProfilePopup userProfile={selectedUserProfile} onClose={handleClosePopup} />
      )}

      {/* Modal for Adding New Enquiry */}
      {isModalOpen && (
        <div className="fixed inset-0 bg-gray-800 bg-opacity-50 flex items-center justify-center">
          <div className="bg-white p-6 rounded shadow-lg w-1/3">
            <h2 className="text-xl mb-4">Add New Enquiry</h2>
            <form onSubmit={handleAddEnquiry}>
              <input
                type="text"
                className="w-full p-2 mb-2 border rounded"
                placeholder="Name"
                value={newEnquiry.name}
                onChange={(e) => setNewEnquiry({ ...newEnquiry, name: e.target.value })}
                required
              />
              <input
                type="text"
                className="w-full p-2 mb-2 border rounded"
                placeholder="City"
                value={newEnquiry.city}
                onChange={(e) => setNewEnquiry({ ...newEnquiry, city: e.target.value })}
                required
              />
              <input
                type="text"
                className="w-full p-2 mb-2 border rounded"
                placeholder="Contact Number"
                value={newEnquiry.contactNumber}
                onChange={(e) => setNewEnquiry({ ...newEnquiry, contactNumber: e.target.value })}
                required
              />
              <input
                type="text"
                className="w-full p-2 mb-2 border rounded"
                placeholder="Problem"
                value={newEnquiry.problem}
                onChange={(e) => setNewEnquiry({ ...newEnquiry, problem: e.target.value })}
                required
              />
              <input
                type="text"
                className="w-full p-2 mb-2 border rounded"
                placeholder="User ID"
                value={newEnquiry.userId}
                onChange={(e) => setNewEnquiry({ ...newEnquiry, userId: e.target.value })}
                required
              />
              <div className="flex justify-end mt-4">
                <button
                  type="button"
                  className="mr-2 px-4 py-2 bg-gray-300 rounded"
                  onClick={() => setIsModalOpen(false)}
                >
                  Cancel
                </button>
                <button type="submit" className="px-4 py-2 bg-blue-500 text-white rounded">
                  Add
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default Enquiry;
