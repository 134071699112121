// src/components/DoctorProfile.js

import React from 'react';
import { auth, provider } from '../config/firebase'; // Adjust the path as per your file structure
import { signInWithPopup } from 'firebase/auth';
import { useNavigate } from 'react-router-dom'; // Optional, use if you want to navigate to a different page after login

const DoctorProfile = () => {
  const navigate = useNavigate(); // Optional for navigation

  // Google Sign-In function
  const handleGoogleSignIn = async () => {
    try {
      const result = await signInWithPopup(auth, provider);
      // Get user information if needed
      const user = result.user;
      console.log('User Signed In:', user);
      // Navigate to Home Page or any other page on success
      navigate('/doctor-dashboard'); // Change this to your desired route
    } catch (error) {
      console.error('Error during sign-in:', error);
    }
  };

  return (
    <div className="flex justify-center items-center h-screen bg-gray-100">
      <div className="bg-white p-8 rounded shadow-md w-full max-w-md">
        <h2 className="text-2xl font-bold mb-6 text-center">Doctor Profile</h2>
        <button
          onClick={handleGoogleSignIn}
          className="flex items-center justify-center w-full bg-blue-500 text-white py-2 rounded-md hover:bg-blue-600 transition duration-200"
        >
          <svg
            className="h-5 w-5 mr-2"
            viewBox="0 0 488 512"
            xmlns="http://www.w3.org/2000/svg"
            fill="currentColor"
          >
            <path d="M488 261.8c0-17.7-1.5-35.6-4.8-52.9H250v100.3h133.4c-5.7 29.5-22.5 54.4-47.9 71v58.5h77.4c45.2-41.6 71.2-103 71.2-176.9z" />
            <path d="M250 512c67.4 0 124-22.4 165.3-60.8l-77.4-58.5c-22.4 15-51.2 23.9-87.9 23.9-67.6 0-124.7-45.6-145.3-107.2h-77.7v66.5C96 457.1 168.8 512 250 512z" />
            <path d="M104.7 304.3c-5.8-17.2-9.1-35.5-9.1-54.3s3.3-37.1 9.1-54.3V129.2h-77.7C11.7 182.7 0 215.8 0 250s11.7 67.3 27 92.8l77.7-38.5z" />
            <path d="M250 100.6c38.2 0 72.5 13.2 99.6 39.2l73.8-73.8C395.5 27.6 339 0 250 0 168.8 0 96 54.9 72.3 133.5l77.7 54.3C125.3 146.2 182.4 100.6 250 100.6z" />
          </svg>
          Sign In with Google
        </button>
      </div>
    </div>
  );
};

export default DoctorProfile;
