// Hospital.js
import React, { useEffect, useState } from 'react';
import { db } from '../config/firebase'; // Ensure the correct path to firebase config
import { collection, getDocs } from 'firebase/firestore';

// Function to shuffle an array using Fisher-Yates algorithm
const shuffleArray = (array) => {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }
  return array;
};

const Hospital = () => {
  const [hospitals, setHospitals] = useState([]);
  const [loading, setLoading] = useState(true); // State to track loading status

  // Fetching hospital data from Firestore
  useEffect(() => {
    const fetchHospitals = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, 'Hospital'));
        const hospitalsData = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        // Shuffle the hospital data before setting state
        setHospitals(shuffleArray(hospitalsData));
      } catch (error) {
        console.error('Error fetching hospital data:', error);
      } finally {
        setLoading(false); // Set loading to false once data is fetched
      }
    };

    fetchHospitals();
  }, []);

  return (
    <div className="p-4">
      <h2 className="text-2xl font-bold mb-4 text-center">Nidaan Partner Hospital</h2>
      
      {/* Progress bar visible while loading */}
      {loading ? (
        <div className="w-full bg-gray-200 rounded-full h-4 mb-4">
          <div className="bg-blue-600 h-4 rounded-full animate-pulse"></div>
        </div>
      ) : (
        <div className="flex overflow-x-auto space-x-4 scrollbar-hide py-2">
          {hospitals.map((hospital) => (
            <div
              key={hospital.id}
              className="min-w-[200px] sm:min-w-[250px] bg-white rounded-lg shadow-md border border-gray-200 flex-shrink-0 transition-transform transform hover:scale-105"
            >
              <img
                src={hospital.hospitalimage}
                alt={hospital.hospitalname}
                className="h-36 w-full object-cover rounded-t-lg"
              />
              <div className="p-4">
                <h3 className="text-lg font-semibold text-gray-800 truncate">
                  {hospital.hospitalname}
                </h3>
                <p className="text-sm text-gray-600">{hospital.hospitalcity}</p>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default Hospital;
