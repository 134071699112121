import React, { useState, useEffect } from 'react';
import { getDatabase, ref, onValue, push, update, remove } from 'firebase/database';
import { Link } from 'react-router-dom';

const Users = () => {
  const [users, setUsers] = useState([]);
  const [newUser, setNewUser] = useState({
    firstName: '',
    lastName: '',
    phoneNumber: '',
    role: '',
    email: '', // Add email to new user state
  });

  useEffect(() => {
    const db = getDatabase();
    const usersRef = ref(db, 'UserInfoNew');

    onValue(usersRef, (snapshot) => {
      const data = snapshot.val();
      if (data) {
        const usersArray = Object.keys(data).map((key) => ({
          id: key,
          ...data[key],
        }));
        setUsers(usersArray);
      }
    });
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewUser({ ...newUser, [name]: value });
  };

  const handleCreateUser = async () => {
    const db = getDatabase();
    const usersRef = ref(db, 'UserInfoNew');

    const newUserRef = push(usersRef);
    await update(newUserRef, {
      ...newUser,
      timestamp: new Date().toISOString(),
      whatsappMessageCount: 0,
      lastWhatsAppMessageTimestamp: '',
    });

    setNewUser({ firstName: '', lastName: '', phoneNumber: '', role: '', email: '' });
  };

  const handleDeleteUser = async (userId) => {
    const db = getDatabase();
    const userRef = ref(db, `UserInfoNew/${userId}`);

    await remove(userRef);
    setUsers(users.filter(user => user.id !== userId));
  };

  const sendWhatsAppMessage = async (user) => {
    const db = getDatabase();
    const userRef = ref(db, `UserProfile/${user.id}`);
    const currentTime = new Date().toISOString();
    
    const message = `Hello ${user.firstName} ${user.lastName},\n\nHere are your details:\n- Mobile Number: ${user.phoneNumber}\n- Role: ${user.role}\n- Email: ${user.email}\n- Timestamp: ${user.timestamp}`;
    const phoneNumber = `+91${user.phoneNumber}`;
    const whatsappLink = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(message)}`;

    window.open(whatsappLink, '_blank');

    const newMessageCount = (user.whatsappMessageCount || 0) + 1;
    await update(userRef, {
      whatsappMessageCount: newMessageCount,
      lastWhatsAppMessageTimestamp: currentTime,
    });

    setUsers((prevUsers) =>
      prevUsers.map((u) =>
        u.id === user.id
          ? {
              ...u,
              whatsappMessageCount: newMessageCount,
              lastWhatsAppMessageTimestamp: currentTime,
            }
          : u
      )
    );
  };

  return (
    <div className="container mx-auto mt-8">
      <h2 className="text-2xl font-bold mb-4">Users List</h2>

      <div className="mb-8">
        <h3 className="text-xl font-semibold mb-2">Create New User</h3>
        <div className="mb-4">
          <input
            type="text"
            name="firstName"
            value={newUser.firstName}
            onChange={handleInputChange}
            placeholder="First Name"
            className="border rounded p-2 mr-2"
          />
          <input
            type="text"
            name="lastName"
            value={newUser.lastName}
            onChange={handleInputChange}
            placeholder="Last Name"
            className="border rounded p-2 mr-2"
          />
          <input
            type="text"
            name="phoneNumber"
            value={newUser.phoneNumber}
            onChange={handleInputChange}
            placeholder="Phone Number"
            className="border rounded p-2 mr-2"
          />
          <input
            type="text"
            name="role"
            value={newUser.role}
            onChange={handleInputChange}
            placeholder="Role"
            className="border rounded p-2 mr-2"
          />
          <input
            type="email"
            name="email"
            value={newUser.email}
            onChange={handleInputChange}
            placeholder="Email"
            className="border rounded p-2 mr-2"
          />
          <button
            onClick={handleCreateUser}
            className="bg-blue-500 text-white py-2 px-4 rounded"
          >
            Add User
          </button>
        </div>
      </div>

      <div className="overflow-x-auto">
        <table className="min-w-full bg-white border border-gray-200">
          <thead>
            <tr>
              <th className="px-4 py-2 border-b">First Name</th>
              <th className="px-4 py-2 border-b">Last Name</th>
              <th className="px-4 py-2 border-b">Mobile Number</th>
              <th className="px-4 py-2 border-b">Email</th> {/* Add Email Header */}
              <th className="px-4 py-2 border-b">Role</th>
              <th className="px-4 py-2 border-b">Timestamp</th>
              <th className="px-4 py-2 border-b">WhatsApp Count</th>
              <th className="px-4 py-2 border-b">Last Sent</th>
              <th className="px-4 py-2 border-b">Actions</th>
            </tr>
          </thead>
          <tbody>
            {users.length > 0 ? (
              users.map((user) => (
                <tr key={user.id}>
                  <td className="px-4 py-2 border-b text-blue-500 underline">
                    <Link to={`/admin-dashboard/edit-user/${user.id}`}>
                      {user.firstName}
                    </Link>
                  </td>
                  <td className="px-4 py-2 border-b">{user.lastName}</td>
                  <td className="px-4 py-2 border-b">{user.phoneNumber}</td>
                  <td className="px-4 py-2 border-b">{user.email}</td> {/* Display Email */}
                  <td className="px-4 py-2 border-b">{user.role}</td>
                  <td className="px-4 py-2 border-b">{user.timestamp}</td>
                  <td className="px-4 py-2 border-b">{user.whatsappMessageCount || 0}</td>
                  <td className="px-4 py-2 border-b">{user.lastWhatsAppMessageTimestamp || 'N/A'}</td>
                  <td className="px-4 py-2 border-b">
                    <button
                      onClick={() => sendWhatsAppMessage(user)}
                      className="bg-green-500 text-white py-2 px-4 rounded mr-2"
                    >
                      Send WhatsApp
                    </button>
                    <button
                      onClick={() => handleDeleteUser(user.id)}
                      className="bg-red-500 text-white py-2 px-4 rounded"
                    >
                      Delete
                    </button>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="9" className="text-center py-4">
                  No users found
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Users;
