// src/components/ErrorPage.js
import React from 'react';
import { useRouteError } from 'react-router-dom';

const ErrorPage = () => {
  const error = useRouteError();
  console.error(error);

  return (
    <div className="flex flex-col items-center justify-center h-screen">
      <h1 className="text-3xl font-bold">Oops!</h1>
      <p className="mt-4">Services not avaliable yet. We will start it Shortly.</p>
      <p className="text-red-500 mt-2">
        <i>{error.statusText || error.message}</i>
      </p>
    </div>
  );
};

export default ErrorPage;
