import React from 'react'

const Booking = () => {
  return (
    <div>
      Booking
    </div>
  )
}

export default Booking
