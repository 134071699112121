import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { getDatabase, ref, get, update } from 'firebase/database';

const EditPartner = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [partnerData, setPartnerData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
    refCode: '',
    status: '',
  });

  useEffect(() => {
    const db = getDatabase();
    const partnerRef = ref(db, `PartnerProfile/${id}`);

    get(partnerRef).then((snapshot) => {
      if (snapshot.exists()) {
        setPartnerData(snapshot.val());
      } else {
        console.log('No data available');
      }
    });
  }, [id]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setPartnerData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const db = getDatabase();
    const partnerRef = ref(db, `PartnerProfile/${id}`);

    update(partnerRef, partnerData)
      .then(() => {
        alert('Partner updated successfully!');
        navigate('/admin-dashboard/partners');
      })
      .catch((error) => {
        console.error('Error updating partner: ', error);
      });
  };

  return (
    <div className="container mx-auto mt-8">
      <h2 className="text-2xl font-bold mb-4">Edit Partner</h2>
      <form onSubmit={handleSubmit} className="max-w-md mx-auto bg-white p-6 rounded shadow">
        <div className="mb-4">
          <label className="block text-gray-700">First Name</label>
          <input
            type="text"
            name="firstName"
            value={partnerData.firstName}
            onChange={handleInputChange}
            className="w-full mt-2 p-2 border rounded"
            required
          />
        </div>
        <div className="mb-4">
          <label className="block text-gray-700">Last Name</label>
          <input
            type="text"
            name="lastName"
            value={partnerData.lastName}
            onChange={handleInputChange}
            className="w-full mt-2 p-2 border rounded"
            required
          />
        </div>
        <div className="mb-4">
          <label className="block text-gray-700">Email</label>
          <input
            type="email"
            name="email"
            value={partnerData.email}
            onChange={handleInputChange}
            className="w-full mt-2 p-2 border rounded"
            required
          />
        </div>
        <div className="mb-4">
          <label className="block text-gray-700">Phone Number</label>
          <input
            type="text"
            name="phoneNumber"
            value={partnerData.phoneNumber}
            onChange={handleInputChange}
            className="w-full mt-2 p-2 border rounded"
            required
          />
        </div>
        <div className="mb-4">
          <label className="block text-gray-700">Referral Code</label>
          <input
            type="text"
            name="refCode"
            value={partnerData.refCode}
            onChange={handleInputChange}
            className="w-full mt-2 p-2 border rounded"
            required
          />
        </div>
        <div className="mb-4">
          <label className="block text-gray-700">Status</label>
          <div className="mt-2">
            <label className="inline-flex items-center">
              <input
                type="radio"
                name="status"
                value="Live"
                checked={partnerData.status === 'Live'}
                onChange={handleInputChange}
                className="form-radio"
              />
              <span className="ml-2">Live</span>
            </label>
            <label className="inline-flex items-center ml-6">
              <input
                type="radio"
                name="status"
                value="Hold"
                checked={partnerData.status === 'Hold'}
                onChange={handleInputChange}
                className="form-radio"
              />
              <span className="ml-2">Hold</span>
            </label>
            <label className="inline-flex items-center ml-6">
              <input
                type="radio"
                name="status"
                value="In Review"
                checked={partnerData.status === 'In Review'}
                onChange={handleInputChange}
                className="form-radio"
              />
              <span className="ml-2">In Review</span>
            </label>
          </div>
        </div>
        <div className="text-center">
          <button
            type="submit"
            className="bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600"
          >
            Update Partner
          </button>
        </div>
      </form>
    </div>
  );
};

export default EditPartner;
