import React from 'react'

const Logo = ({w,h}) => {
  return (
    <svg width={w} height={h} 
	viewBox="0 0 1542 646" enable-background="new 0 0 1542 646" >
<path fill="#FFFFFF" opacity="1.000000" stroke="none" 
	d="
M929.000000,647.000000 
	C619.368591,647.000000 310.237183,647.000000 1.052879,647.000000 
	C1.052879,431.726471 1.052879,216.452957 1.052879,1.089708 
	C514.881592,1.089708 1028.763184,1.089708 1542.822388,1.089708 
	C1542.822388,216.333221 1542.822388,431.666595 1542.822388,647.000000 
	C1338.452637,647.000000 1133.976318,647.000000 929.000000,647.000000 
M362.235596,437.282471 
	C362.235596,437.282471 362.345551,437.376648 362.771210,437.244568 
	C362.771210,437.244568 362.971100,436.846283 363.436432,436.722870 
	C363.696808,436.335022 363.957184,435.947174 364.825958,435.223145 
	C375.695282,422.770721 386.814636,410.524261 397.319550,397.771667 
	C401.986267,392.106476 405.412903,385.419800 410.047638,379.051483 
	C420.370422,363.528137 428.471161,346.926605 434.814850,329.389374 
	C444.219727,303.389374 449.456635,276.872955 444.986176,249.211807 
	C442.112427,231.430420 435.708344,215.007828 423.881622,200.475143 
	C423.612000,200.259735 423.342407,200.044327 422.847870,199.215439 
	C421.199371,197.491104 419.550873,195.766754 417.700897,193.404282 
	C410.207855,187.526093 403.040985,181.158417 395.139465,175.893280 
	C385.199951,169.270172 373.772247,165.871521 362.089020,163.738983 
	C355.476501,162.531982 348.715942,162.136108 341.197906,161.172012 
	C339.794250,161.247864 338.390564,161.323730 336.129578,161.239624 
	C331.764069,161.850250 327.398590,162.460861 322.185059,162.963089 
	C310.068665,164.925827 298.589172,168.707520 287.259033,175.022324 
	C278.167114,180.000290 269.934967,186.133743 263.798584,194.533936 
	C259.965149,199.781616 258.274658,200.063751 253.460510,195.495743 
	C247.645950,189.978485 241.444626,184.868805 235.388214,179.619827 
	C235.388214,179.619827 235.347046,179.629456 234.996307,179.075943 
	C233.020737,177.953964 231.045181,176.831970 228.662857,175.183258 
	C213.447449,167.099228 197.360886,162.132843 179.148041,161.272537 
	C166.089264,160.954742 153.425934,163.173325 140.299911,167.198990 
	C131.428345,171.741867 122.556786,176.284744 113.058273,181.064682 
	C104.893372,185.548187 98.465256,191.922424 92.582909,199.806503 
	C76.799248,219.365814 67.967102,241.398010 67.943504,266.834961 
	C67.926086,285.605103 71.957970,303.431000 78.518074,320.887299 
	C86.163391,341.231293 96.201439,360.302124 108.337128,379.143677 
	C110.094833,381.960968 111.559494,385.025085 113.677017,387.539337 
	C117.092087,391.594269 118.411377,395.611664 117.426956,401.141602 
	C116.310707,407.412170 120.414772,412.346832 126.508179,414.208649 
	C132.377625,416.001984 138.137680,414.047485 141.317657,409.097595 
	C144.255997,404.523773 144.466949,399.751068 142.005829,394.917755 
	C139.356995,389.715759 134.264069,388.570740 129.317902,388.638031 
	C124.489304,388.703705 122.119652,386.553680 119.975471,382.758789 
	C117.640739,378.626740 114.744049,374.812195 112.059784,370.947205 
	C112.059784,370.947205 112.149902,370.921112 112.026741,370.461365 
	C112.026741,370.461365 111.813171,370.035980 111.787735,369.339874 
	C105.202240,356.025970 95.603287,344.381500 90.764381,330.055237 
	C85.459206,314.348511 78.996521,299.006073 77.415581,282.274323 
	C75.324707,260.145782 77.254837,238.761078 89.397194,219.365128 
	C93.078049,213.485397 97.058372,207.793152 101.024940,201.943680 
	C101.024940,201.943680 100.969994,201.812759 101.307480,201.588867 
	C101.515488,201.413376 101.694450,201.212082 102.507431,200.952850 
	C103.347305,199.972244 104.187180,198.991623 105.477463,197.867264 
	C105.781471,197.664291 105.884666,197.386658 106.325882,196.684021 
	C107.226906,195.803925 108.127937,194.923828 109.613136,193.900925 
	C110.547867,193.245621 111.482597,192.590332 112.888229,191.680984 
	C112.962730,191.518936 113.037239,191.356888 113.643776,191.001480 
	C113.775551,190.675430 113.907333,190.349380 114.383972,189.518738 
	C115.261246,188.678528 116.138519,187.838333 117.740761,187.058838 
	C128.825241,182.031769 139.909698,177.004700 151.806351,172.043411 
	C154.174988,171.590286 156.543625,171.137161 159.493362,170.808441 
	C160.011566,170.589142 160.529770,170.369843 161.939178,170.197922 
	C164.314102,170.021301 166.689026,169.844681 169.824432,169.821564 
	C170.847382,169.738495 171.870316,169.655426 173.589905,169.749359 
	C174.771332,169.658630 175.952759,169.567902 177.840652,169.657333 
	C178.557480,169.579742 179.274292,169.502151 180.179962,169.542526 
	C180.179962,169.542526 180.359024,169.410187 181.159012,169.724777 
	C194.007156,170.422684 206.485275,172.976776 217.840698,179.087708 
	C225.503403,183.211380 232.562607,188.456467 240.194550,193.785217 
	C245.016251,199.030685 250.163483,204.021210 254.542953,209.613052 
	C257.369110,213.221634 258.935486,212.489441 261.287292,209.534241 
	C271.181580,197.101227 282.564758,186.390839 297.624817,179.155685 
	C298.404175,178.723724 299.183502,178.291779 300.545410,177.900757 
	C301.020782,177.585831 301.496185,177.270905 302.860291,176.845993 
	C303.397308,176.589432 303.934326,176.332855 304.403778,175.989426 
	C304.403778,175.989426 304.284393,176.020096 305.092499,175.807175 
	C315.406342,171.956665 326.081360,170.004868 337.978912,169.892136 
	C351.224457,169.525208 364.006958,171.684784 376.417877,176.425705 
	C390.992432,181.993149 403.301727,190.773956 414.250824,202.607117 
	C425.691010,216.059341 433.049713,231.444153 435.948822,248.882294 
	C440.934509,278.871429 435.316833,307.485718 423.685638,335.016998 
	C418.212128,347.973022 411.194183,360.276581 404.920502,372.909485 
	C404.920502,372.909485 404.901825,372.859741 404.320435,373.185791 
	C403.567993,374.538696 402.815521,375.891632 401.548248,377.635254 
	C392.106049,389.833710 383.129517,402.433777 373.068756,414.098724 
	C365.532013,422.837280 356.599121,430.371674 348.318665,438.470917 
	C348.318665,438.470917 348.284485,438.476532 347.776825,438.752014 
	C347.108826,439.440979 346.440857,440.129974 345.908813,440.913452 
	C345.908813,440.913452 345.821198,440.772949 345.240601,440.980530 
	C344.355743,441.795898 343.470886,442.611267 342.509827,443.499573 
	C342.509827,443.499573 342.404877,443.488403 341.844055,443.708496 
	C340.894043,444.473633 339.944031,445.238739 338.347656,446.224487 
	C324.492035,454.415924 310.636444,462.607330 296.904297,470.841675 
	C296.904297,470.841675 296.890045,470.711761 296.203400,470.733551 
	C295.391205,471.079559 294.579041,471.425537 293.897919,471.807587 
	C293.897919,471.807587 293.868317,471.674927 293.200653,471.735504 
	C292.422577,472.143311 291.644501,472.551086 290.087646,472.916656 
	C283.725677,474.619995 277.363678,476.323303 270.080963,477.973206 
	C267.595978,478.090240 265.102142,478.111572 262.627350,478.339355 
	C241.047989,480.325287 221.569168,475.454834 204.927277,460.376923 
	C204.749191,460.097443 204.494476,459.954529 203.908447,459.311523 
	C200.272598,453.871521 196.636734,448.431549 192.998962,442.999969 
	C192.998962,442.999969 193.004745,443.001343 192.989227,442.198517 
	C188.719543,430.158508 189.095779,418.087341 193.018127,405.554901 
	C193.016052,405.382751 193.013962,405.210571 193.531769,404.596680 
	C194.693054,402.065308 195.854324,399.533936 197.475128,396.763184 
	C197.692627,396.306244 197.910126,395.849304 198.699997,395.030853 
	C199.456207,393.682037 200.212418,392.333221 201.004074,390.995850 
	C201.004074,390.995850 200.981171,390.965607 201.555954,390.637054 
	C203.313568,388.874756 204.822052,386.717957 206.874191,385.422028 
	C213.798935,381.049255 220.916733,376.982208 228.347534,373.053711 
	C228.347534,373.053711 228.810593,373.075500 229.064072,373.980865 
	C229.291397,377.112915 229.706223,380.244507 229.714005,383.377106 
	C229.750336,397.993439 229.597000,412.610687 229.726746,427.225739 
	C229.744949,429.275360 230.953552,431.314392 231.636215,433.119812 
	C231.636215,433.119812 231.657120,433.358734 231.863007,433.866089 
	C232.200623,433.980164 232.538223,434.094269 232.990402,434.108582 
	C232.990402,434.108582 232.848083,434.161713 233.427292,434.803375 
	C250.074356,434.892609 266.721375,434.997131 283.368561,435.036072 
	C284.459961,435.038605 285.552582,434.538940 287.366211,434.314606 
	C288.532684,432.494568 290.699646,430.681427 290.712280,428.853363 
	C290.852386,408.604584 290.658447,388.353516 290.697906,367.199310 
	C290.060333,363.475922 291.943481,362.827606 295.256256,362.854767 
	C314.690308,363.014343 334.125977,362.992950 353.561096,362.982208 
	C355.351471,362.981201 357.141663,362.626556 359.615143,362.393219 
	C360.762512,360.743805 362.881805,359.109131 362.907135,357.442657 
	C363.165314,340.472321 363.112671,323.496674 363.070862,306.522705 
	C363.062012,302.918152 361.059509,301.013489 357.420624,301.022156 
	C353.264557,301.032043 349.108643,301.114807 344.031677,301.018982 
	C334.796875,301.037201 325.560974,300.987579 316.328247,301.135498 
	C314.645142,301.162476 312.335785,301.460083 311.417816,302.556030 
	C308.594360,305.927063 306.305573,309.745972 303.231049,313.781097 
	C301.654114,315.646393 300.077179,317.511688 298.487976,319.491882 
	C298.487976,319.491882 298.380920,319.535309 297.859375,319.871338 
	C296.208649,321.893555 294.557922,323.915802 292.989441,325.987610 
	C292.989441,325.987610 292.937592,325.906769 292.317993,326.165955 
	C287.545959,330.443970 282.773926,334.721985 278.000000,339.000000 
	C278.000000,339.000000 277.999390,339.001434 277.403839,339.075104 
	C276.807648,339.578400 276.211456,340.081726 275.731842,340.686615 
	C275.731842,340.686615 275.700134,340.535309 275.043854,340.675446 
	C273.626221,341.728210 272.208618,342.781006 270.899628,343.873383 
	C270.899628,343.873383 270.888977,343.758240 270.189453,343.856354 
	C268.023163,345.197418 265.856903,346.538483 263.026886,347.960449 
	C261.499847,348.890137 259.972809,349.819824 257.994415,350.723694 
	C257.628540,350.968414 257.262665,351.213135 256.144806,351.597748 
	C253.753220,352.722504 251.361633,353.847290 248.233185,355.004181 
	C246.259445,355.907684 244.285706,356.811188 242.170425,357.631958 
	C242.170425,357.631958 242.017960,357.692200 241.232758,357.695282 
	C233.498734,361.470276 225.764709,365.245239 217.565292,369.014191 
	C217.229691,369.078979 217.000046,369.270691 216.155640,369.770630 
	C211.286575,373.129700 206.417496,376.488770 201.164230,380.041260 
	C201.164230,380.041260 201.028931,380.449585 200.457123,380.701233 
	C196.652985,386.137054 192.848831,391.572876 188.626465,397.368256 
	C188.363174,398.036682 188.099899,398.705109 187.258881,399.850098 
	C186.535919,401.903076 185.812943,403.956055 184.871292,406.376129 
	C184.871292,406.376129 184.939178,406.797974 184.458374,407.375031 
	C180.661392,419.293671 180.609818,431.152863 184.971848,443.497040 
	C185.054611,443.663666 185.137375,443.830322 185.218414,444.704681 
	C186.098038,446.634796 186.977676,448.564911 187.846725,451.035370 
	C188.007736,451.352478 188.168747,451.669586 188.404160,452.665222 
	C191.297791,456.707611 194.191437,460.750031 197.071854,464.939392 
	C197.071854,464.939392 197.217072,464.913269 197.487106,465.507690 
	C200.596817,468.093323 203.706528,470.678925 206.883301,473.140961 
	C206.883301,473.140961 206.755280,473.199036 207.014923,473.821930 
	C216.845108,480.893433 227.904556,484.691223 239.856766,486.120544 
	C239.856766,486.120544 239.862366,486.133453 240.091019,486.514648 
	C240.091019,486.514648 240.512497,486.655731 240.512497,486.655731 
	C240.512497,486.655731 240.949280,486.573364 241.726517,486.859924 
	C242.825714,486.862244 243.924927,486.864563 245.362259,487.140259 
	C245.911469,487.107758 246.460693,487.075256 247.483414,487.287415 
	C247.829315,487.248718 248.175217,487.210052 249.240234,487.720123 
	C255.000137,487.537567 260.760040,487.355011 267.275513,487.240173 
	C268.527740,487.083130 269.779938,486.926056 271.746429,486.967224 
	C272.669800,486.709351 273.593201,486.451477 275.328094,486.217865 
	C281.887238,484.490234 288.446381,482.762604 295.769318,481.046021 
	C299.248993,479.386475 302.728699,477.726929 306.607269,476.074982 
	C306.607269,476.074982 306.979401,475.931000 307.724457,475.973724 
	C323.837799,468.771423 338.133759,458.818481 351.924683,446.956268 
	C352.838013,446.024414 353.751373,445.092560 355.128723,444.089874 
	C355.427124,443.751923 355.725525,443.414001 356.590210,442.910675 
	C357.086761,442.321686 357.583282,441.732727 358.654694,440.989960 
	C359.338379,440.320892 360.022064,439.651855 361.139252,438.728668 
	C361.193848,438.570282 361.248474,438.411926 361.181152,438.175323 
	C361.181152,438.175323 361.174194,438.320068 361.737701,438.178864 
	C361.950928,437.870270 362.164124,437.561707 362.235596,437.282471 
M272.057465,314.288910 
	C272.276703,313.858521 272.495911,313.428101 273.065521,312.696991 
	C273.065521,312.696991 273.114685,312.237885 273.689117,311.846100 
	C277.121277,304.907104 280.959198,298.133270 283.870026,290.982056 
	C286.712006,284.000031 289.943542,276.755463 290.618683,269.406982 
	C291.756042,257.027039 290.948059,244.473190 291.085175,231.993896 
	C291.131927,227.737488 289.072021,225.953079 285.049530,225.943375 
	C279.392609,225.929718 273.735718,225.918030 267.798248,225.342575 
	C267.198090,225.303955 266.597961,225.265335 265.104736,225.107269 
	C262.404907,225.178848 259.705078,225.250427 256.092163,225.138870 
	C251.733139,225.212616 247.374100,225.286362 242.225372,225.173309 
	C241.150513,225.243668 240.075638,225.314041 238.186798,225.199417 
	C234.287003,225.293030 229.902451,225.082733 229.899689,230.635468 
	C229.889847,250.400635 230.092743,270.165924 230.062546,290.710419 
	C230.121948,291.497040 230.181351,292.283661 229.973175,293.784790 
	C229.286957,297.549438 227.028625,298.292389 223.441315,297.858398 
	C219.695160,297.405212 215.851425,297.758698 211.161224,297.423706 
	C205.114731,297.581299 199.068222,297.738892 192.316254,297.488068 
	C182.656982,297.445190 172.997330,297.440308 163.338684,297.338226 
	C160.090302,297.303925 158.066147,298.849152 158.004608,301.957031 
	C157.678375,318.432098 157.389984,334.911407 157.446136,351.387970 
	C157.458862,355.125031 159.179016,358.758026 163.921341,358.790436 
	C172.240829,358.847260 180.561768,358.690094 189.820862,358.803558 
	C194.547363,358.724396 199.273865,358.645233 204.796066,358.773346 
	C205.884674,358.727417 206.973297,358.681488 208.496155,358.802612 
	C208.496155,358.802612 208.940659,358.665070 209.745758,358.845856 
	C210.835632,358.774811 211.925491,358.703796 213.808563,358.830658 
	C214.895081,358.797760 215.981598,358.764862 217.878738,358.954437 
	C219.538696,358.843018 221.198654,358.731598 223.452759,358.708954 
	C224.373520,358.397186 225.294266,358.085419 226.994064,357.737305 
	C228.813812,356.943237 230.919724,356.501740 232.405243,355.295929 
	C240.108002,349.043701 248.223602,343.148590 255.083115,336.053711 
	C261.308411,329.614777 266.106079,321.795593 271.569336,314.527893 
	C271.569336,314.527893 271.601410,314.469147 272.057465,314.288910 
M525.842834,283.541687 
	C525.842834,280.015778 525.842834,276.489899 525.842834,272.963989 
	C527.582153,273.956818 528.339294,275.009430 529.048340,276.093536 
	C552.093872,311.330170 575.068970,346.613342 598.253601,381.758209 
	C601.427734,386.569763 605.146118,391.271423 609.486694,395.008545 
	C616.804321,401.308777 625.705200,401.886200 634.423767,398.458160 
	C642.656494,395.221161 644.619751,387.679962 645.162415,379.681244 
	C645.342285,377.029388 645.529663,374.370758 645.531006,371.715149 
	C645.553894,326.059174 645.560791,280.403168 645.523865,234.747208 
	C645.520996,231.256027 645.362732,227.740555 644.933655,224.278870 
	C643.776855,214.945709 637.734253,209.211456 628.938232,208.846481 
	C619.500000,208.454880 613.269958,213.358780 611.360840,222.963684 
	C610.846985,225.548935 610.850525,228.264023 610.847839,230.919739 
	C610.813293,265.078400 610.820740,299.237061 610.817505,333.395721 
	C610.817383,334.984375 610.817505,336.573029 610.817505,339.300201 
	C609.447205,337.576263 608.709045,336.778900 608.117615,335.884552 
	C586.450623,303.119507 564.805969,270.339661 543.134949,237.577225 
	C538.453796,230.500153 534.043335,223.203491 528.860413,216.509521 
	C523.047302,209.001511 514.897766,207.129486 505.979431,209.852158 
	C497.023621,212.586227 491.771301,218.758026 490.889709,228.269012 
	C490.551788,231.914429 490.190002,235.576340 490.186035,239.231125 
	C490.136780,284.387177 490.132812,329.543335 490.185852,374.699371 
	C490.190155,378.350037 490.463440,382.036530 491.020203,385.643341 
	C492.438995,394.834381 499.043640,400.260162 508.360382,400.174805 
	C517.237671,400.093475 523.639648,394.510315 524.999207,385.445435 
	C525.491272,382.164825 525.801025,378.823303 525.807556,375.508789 
	C525.867615,345.182678 525.842590,314.856354 525.842834,283.541687 
M1355.845703,281.576019 
	C1355.845703,278.549805 1355.845703,275.523590 1355.845703,271.257538 
	C1357.707153,273.920197 1358.775879,275.360382 1359.753296,276.860107 
	C1381.476440,310.188599 1403.071411,343.601624 1425.002930,376.792419 
	C1429.458252,383.535004 1434.522705,390.166107 1440.481201,395.547821 
	C1447.314209,401.719543 1456.255859,401.766052 1464.544434,398.497375 
	C1472.232544,395.465546 1474.615967,388.395905 1475.208374,380.688019 
	C1475.399536,378.199493 1475.514526,375.698425 1475.515381,373.203033 
	C1475.532349,326.207275 1475.534424,279.211548 1475.505859,232.215805 
	C1475.504272,229.557098 1475.366333,226.879333 1475.031860,224.243439 
	C1473.870728,215.095810 1467.983154,209.342438 1459.490723,208.897888 
	C1449.778320,208.389481 1443.725220,212.879242 1441.705078,222.575272 
	C1440.966797,226.118622 1440.865601,229.841553 1440.859131,233.483246 
	C1440.800781,266.646606 1440.827393,299.810089 1440.829102,332.973541 
	C1440.829224,334.619324 1440.829224,336.265106 1440.829224,337.910919 
	C1438.873413,336.782227 1437.979370,335.603882 1437.165894,334.372284 
	C1417.349243,304.373077 1397.557495,274.357330 1377.721436,244.370880 
	C1371.479492,234.934677 1365.489624,225.299026 1358.705688,216.264191 
	C1352.958496,208.609894 1344.608032,207.178040 1335.757690,209.971085 
	C1327.005859,212.733078 1321.941895,218.895477 1320.995117,228.150574 
	C1320.690552,231.128555 1320.502563,234.132828 1320.500488,237.125229 
	C1320.468506,283.954315 1320.469238,330.783386 1320.493042,377.612488 
	C1320.494263,379.937592 1320.643433,382.279449 1320.944092,384.584717 
	C1322.178345,394.044525 1328.475220,399.956024 1337.428467,400.172974 
	C1347.001953,400.404907 1353.424805,395.127533 1355.127563,385.476837 
	C1355.558594,383.033997 1355.801758,380.524353 1355.805664,378.044922 
	C1355.854492,346.214539 1355.844360,314.384094 1355.845703,281.576019 
M856.659668,212.849228 
	C852.677490,212.562256 848.696411,212.045578 844.713013,212.027512 
	C826.059875,211.942947 807.405823,211.946014 788.752502,212.015884 
	C775.051575,212.067200 768.578735,218.023712 767.269714,231.607437 
	C767.110535,233.259323 767.171143,234.934769 767.170959,236.599289 
	C767.166321,279.735870 767.149231,322.872467 767.193970,366.008972 
	C767.198669,370.492310 767.296936,375.008972 767.834045,379.451874 
	C769.315491,391.706604 775.232605,396.917297 787.740479,396.977448 
	C807.559570,397.072754 827.381287,397.182648 847.198425,396.964783 
	C883.214661,396.568878 909.585693,378.081238 919.689758,345.282867 
	C927.970276,318.404175 928.065674,291.098083 919.783508,264.159271 
	C910.640930,234.421799 887.195007,215.502319 856.659668,212.849228 
M991.086365,235.554245 
	C980.579590,262.312164 970.076538,289.071533 959.564697,315.827454 
	C952.151367,334.696808 944.658875,353.535492 937.346069,372.443665 
	C935.095642,378.262482 933.617676,384.351349 936.918396,390.295441 
	C940.501404,396.747925 945.963135,400.130341 953.568542,400.234222 
	C960.892700,400.334259 965.610168,396.846832 968.239624,390.400330 
	C972.755554,379.328918 977.121277,368.193542 981.344727,357.007507 
	C982.413696,354.176392 983.614441,352.883392 986.949158,352.910187 
	C1009.278564,353.089447 1031.610474,353.060181 1053.940552,352.934357 
	C1056.880005,352.917816 1058.202515,353.768921 1059.291504,356.582001 
	C1063.729126,368.045441 1068.122437,379.555206 1073.281006,390.701324 
	C1077.372192,399.541321 1086.294922,402.504303 1095.180786,399.075531 
	C1103.911133,395.706757 1108.392944,387.433777 1106.266968,378.249207 
	C1105.484009,374.866760 1104.499878,371.490204 1103.204590,368.273224 
	C1086.942749,327.888306 1070.660400,287.511383 1054.249878,247.186844 
	C1050.499878,237.972198 1047.192627,228.377335 1042.012939,219.987732 
	C1032.280151,204.223343 1008.001404,204.761230 998.247375,220.472794 
	C995.463318,224.957275 993.623657,230.028076 991.086365,235.554245 
M1277.924072,340.530945 
	C1265.903320,310.789032 1253.920044,281.031891 1241.840454,251.313889 
	C1237.902100,241.625000 1234.133423,231.832748 1229.549927,222.447845 
	C1220.249390,203.404343 1190.678711,204.482376 1182.487915,223.019730 
	C1181.213013,225.905273 1179.657471,228.674347 1178.500488,231.603424 
	C1163.730469,268.997711 1148.960083,306.392120 1134.305542,343.831757 
	C1129.828125,355.270508 1125.192505,366.689819 1121.580078,378.411041 
	C1118.764160,387.548035 1124.141602,396.127991 1133.222168,399.304260 
	C1142.399414,402.514313 1149.944580,399.563019 1154.052490,390.176086 
	C1158.910278,379.075500 1162.961182,367.623840 1167.588989,356.418121 
	C1168.178467,354.990387 1170.129028,353.158600 1171.462524,353.147614 
	C1195.450562,352.949799 1219.440796,353.003723 1243.592163,353.003723 
	C1244.181274,354.520294 1244.690063,355.894684 1245.245728,357.249939 
	C1249.784790,368.319733 1254.021240,379.527649 1258.975708,390.408569 
	C1262.954590,399.146515 1271.405640,402.336426 1280.257080,399.378784 
	C1289.045654,396.442169 1294.730713,387.910583 1292.027954,379.088165 
	C1288.100464,366.267426 1282.856445,353.850037 1277.924072,340.530945 
M687.823730,303.499969 
	C687.823730,323.478607 687.814331,343.457214 687.829346,363.435852 
	C687.834106,369.761536 687.621338,376.104553 688.009705,382.408783 
	C688.623352,392.370819 695.486328,399.567322 704.369812,400.124542 
	C715.235596,400.806061 722.414490,395.590302 724.652832,385.146301 
	C725.168823,382.738586 725.147217,380.187134 725.148438,377.701752 
	C725.172363,328.920715 725.164795,280.139679 725.154114,231.358627 
	C725.153748,229.528992 725.182922,227.685852 724.986023,225.872040 
	C723.923523,216.085999 717.564697,209.631104 708.388855,208.932510 
	C696.079285,207.995346 687.954834,215.793060 687.880615,229.079010 
	C687.743958,253.551987 687.830322,278.026215 687.823730,303.499969 
M152.691498,404.967896 
	C153.764053,394.319275 149.517990,386.256287 140.346893,381.526245 
	C134.517090,378.519531 129.733414,378.334869 126.030289,381.743896 
	C128.377121,382.263092 130.096313,382.799469 131.854309,383.006805 
	C142.474991,384.259186 150.270996,393.778534 148.820587,403.814697 
	C147.231506,414.810272 137.391647,421.625458 126.259117,419.440948 
	C117.651451,417.751892 111.951469,410.033630 112.030777,400.555817 
	C112.051331,398.099396 111.311424,395.636627 110.920822,393.176758 
	C110.463341,393.265808 110.005852,393.354828 109.548370,393.443878 
	C104.967506,406.669464 111.114883,419.673462 123.813293,423.291840 
	C138.052460,427.349243 148.670456,420.085449 152.691498,404.967896 
z"/>
<path fill="#2B2B2B" opacity="1.000000" stroke="none" 
	d="
M525.842590,284.035889 
	C525.842590,314.856354 525.867615,345.182678 525.807556,375.508789 
	C525.801025,378.823303 525.491272,382.164825 524.999207,385.445435 
	C523.639648,394.510315 517.237671,400.093475 508.360382,400.174805 
	C499.043640,400.260162 492.438995,394.834381 491.020203,385.643341 
	C490.463440,382.036530 490.190155,378.350037 490.185852,374.699371 
	C490.132812,329.543335 490.136780,284.387177 490.186035,239.231125 
	C490.190002,235.576340 490.551788,231.914429 490.889709,228.269012 
	C491.771301,218.758026 497.023621,212.586227 505.979431,209.852158 
	C514.897766,207.129486 523.047302,209.001511 528.860413,216.509521 
	C534.043335,223.203491 538.453796,230.500153 543.134949,237.577225 
	C564.805969,270.339661 586.450623,303.119507 608.117615,335.884552 
	C608.709045,336.778900 609.447205,337.576263 610.817505,339.300201 
	C610.817505,336.573029 610.817383,334.984375 610.817505,333.395721 
	C610.820740,299.237061 610.813293,265.078400 610.847839,230.919739 
	C610.850525,228.264023 610.846985,225.548935 611.360840,222.963684 
	C613.269958,213.358780 619.500000,208.454880 628.938232,208.846481 
	C637.734253,209.211456 643.776855,214.945709 644.933655,224.278870 
	C645.362732,227.740555 645.520996,231.256027 645.523865,234.747208 
	C645.560791,280.403168 645.553894,326.059174 645.531006,371.715149 
	C645.529663,374.370758 645.342285,377.029388 645.162415,379.681244 
	C644.619751,387.679962 642.656494,395.221161 634.423767,398.458160 
	C625.705200,401.886200 616.804321,401.308777 609.486694,395.008545 
	C605.146118,391.271423 601.427734,386.569763 598.253601,381.758209 
	C575.068970,346.613342 552.093872,311.330170 529.048340,276.093536 
	C528.339294,275.009430 527.582153,273.956818 525.842834,272.963989 
	C525.842834,276.489899 525.842834,280.015778 525.842590,284.035889 
z"/>
<path fill="#2B2B2B" opacity="1.000000" stroke="none" 
	d="
M1355.847412,282.064819 
	C1355.844360,314.384094 1355.854492,346.214539 1355.805664,378.044922 
	C1355.801758,380.524353 1355.558594,383.033997 1355.127563,385.476837 
	C1353.424805,395.127533 1347.001953,400.404907 1337.428467,400.172974 
	C1328.475220,399.956024 1322.178345,394.044525 1320.944092,384.584717 
	C1320.643433,382.279449 1320.494263,379.937592 1320.493042,377.612488 
	C1320.469238,330.783386 1320.468506,283.954315 1320.500488,237.125229 
	C1320.502563,234.132828 1320.690552,231.128555 1320.995117,228.150574 
	C1321.941895,218.895477 1327.005859,212.733078 1335.757690,209.971085 
	C1344.608032,207.178040 1352.958496,208.609894 1358.705688,216.264191 
	C1365.489624,225.299026 1371.479492,234.934677 1377.721436,244.370880 
	C1397.557495,274.357330 1417.349243,304.373077 1437.165894,334.372284 
	C1437.979370,335.603882 1438.873413,336.782227 1440.829224,337.910919 
	C1440.829224,336.265106 1440.829224,334.619324 1440.829102,332.973541 
	C1440.827393,299.810089 1440.800781,266.646606 1440.859131,233.483246 
	C1440.865601,229.841553 1440.966797,226.118622 1441.705078,222.575272 
	C1443.725220,212.879242 1449.778320,208.389481 1459.490723,208.897888 
	C1467.983154,209.342438 1473.870728,215.095810 1475.031860,224.243439 
	C1475.366333,226.879333 1475.504272,229.557098 1475.505859,232.215805 
	C1475.534424,279.211548 1475.532349,326.207275 1475.515381,373.203033 
	C1475.514526,375.698425 1475.399536,378.199493 1475.208374,380.688019 
	C1474.615967,388.395905 1472.232544,395.465546 1464.544434,398.497375 
	C1456.255859,401.766052 1447.314209,401.719543 1440.481201,395.547821 
	C1434.522705,390.166107 1429.458252,383.535004 1425.002930,376.792419 
	C1403.071411,343.601624 1381.476440,310.188599 1359.753296,276.860107 
	C1358.775879,275.360382 1357.707153,273.920197 1355.845703,271.257538 
	C1355.845703,275.523590 1355.845703,278.549805 1355.847412,282.064819 
z"/>
<path fill="#2A2A2A" opacity="1.000000" stroke="none" 
	d="
M857.109253,212.895569 
	C887.195007,215.502319 910.640930,234.421799 919.783508,264.159271 
	C928.065674,291.098083 927.970276,318.404175 919.689758,345.282867 
	C909.585693,378.081238 883.214661,396.568878 847.198425,396.964783 
	C827.381287,397.182648 807.559570,397.072754 787.740479,396.977448 
	C775.232605,396.917297 769.315491,391.706604 767.834045,379.451874 
	C767.296936,375.008972 767.198669,370.492310 767.193970,366.008972 
	C767.149231,322.872467 767.166321,279.735870 767.170959,236.599289 
	C767.171143,234.934769 767.110535,233.259323 767.269714,231.607437 
	C768.578735,218.023712 775.051575,212.067200 788.752502,212.015884 
	C807.405823,211.946014 826.059875,211.942947 844.713013,212.027512 
	C848.696411,212.045578 852.677490,212.562256 857.109253,212.895569 
M805.003235,309.500000 
	C805.003235,328.591034 805.003235,347.682068 805.003235,366.914734 
	C818.431824,366.914734 831.247803,367.163666 844.050293,366.854645 
	C861.907166,366.423615 874.528687,357.544495 880.819641,341.050507 
	C889.716370,317.724854 889.964233,293.749908 882.211487,270.117615 
	C877.491394,255.729568 867.410095,245.929123 851.740540,244.043823 
	C840.543335,242.696609 829.207458,242.461823 817.926025,241.874252 
	C813.690552,241.653656 809.433960,241.838516 805.003235,241.838516 
	C805.003235,264.540497 805.003235,286.520233 805.003235,309.500000 
z"/>
<path fill="#2B2B2B" opacity="1.000000" stroke="none" 
	d="
M991.218628,235.192139 
	C993.623657,230.028076 995.463318,224.957275 998.247375,220.472794 
	C1008.001404,204.761230 1032.280151,204.223343 1042.012939,219.987732 
	C1047.192627,228.377335 1050.499878,237.972198 1054.249878,247.186844 
	C1070.660400,287.511383 1086.942749,327.888306 1103.204590,368.273224 
	C1104.499878,371.490204 1105.484009,374.866760 1106.266968,378.249207 
	C1108.392944,387.433777 1103.911133,395.706757 1095.180786,399.075531 
	C1086.294922,402.504303 1077.372192,399.541321 1073.281006,390.701324 
	C1068.122437,379.555206 1063.729126,368.045441 1059.291504,356.582001 
	C1058.202515,353.768921 1056.880005,352.917816 1053.940552,352.934357 
	C1031.610474,353.060181 1009.278564,353.089447 986.949158,352.910187 
	C983.614441,352.883392 982.413696,354.176392 981.344727,357.007507 
	C977.121277,368.193542 972.755554,379.328918 968.239624,390.400330 
	C965.610168,396.846832 960.892700,400.334259 953.568542,400.234222 
	C945.963135,400.130341 940.501404,396.747925 936.918396,390.295441 
	C933.617676,384.351349 935.095642,378.262482 937.346069,372.443665 
	C944.658875,353.535492 952.151367,334.696808 959.564697,315.827454 
	C970.076538,289.071533 980.579590,262.312164 991.218628,235.192139 
M1014.265015,264.782776 
	C1007.251648,284.432892 1000.238342,304.083008 993.092590,324.104156 
	C1011.413025,324.104156 1029.227417,324.104156 1047.511841,324.104156 
	C1038.365234,299.104980 1029.422974,274.664185 1020.005371,248.924316 
	C1017.835083,255.017624 1016.221924,259.546722 1014.265015,264.782776 
z"/>
<path fill="#2B2B2B" opacity="1.000000" stroke="none" 
	d="
M1278.048950,340.895782 
	C1282.856445,353.850037 1288.100464,366.267426 1292.027954,379.088165 
	C1294.730713,387.910583 1289.045654,396.442169 1280.257080,399.378784 
	C1271.405640,402.336426 1262.954590,399.146515 1258.975708,390.408569 
	C1254.021240,379.527649 1249.784790,368.319733 1245.245728,357.249939 
	C1244.690063,355.894684 1244.181274,354.520294 1243.592163,353.003723 
	C1219.440796,353.003723 1195.450562,352.949799 1171.462524,353.147614 
	C1170.129028,353.158600 1168.178467,354.990387 1167.588989,356.418121 
	C1162.961182,367.623840 1158.910278,379.075500 1154.052490,390.176086 
	C1149.944580,399.563019 1142.399414,402.514313 1133.222168,399.304260 
	C1124.141602,396.127991 1118.764160,387.548035 1121.580078,378.411041 
	C1125.192505,366.689819 1129.828125,355.270508 1134.305542,343.831757 
	C1148.960083,306.392120 1163.730469,268.997711 1178.500488,231.603424 
	C1179.657471,228.674347 1181.213013,225.905273 1182.487915,223.019730 
	C1190.678711,204.482376 1220.249390,203.404343 1229.549927,222.447845 
	C1234.133423,231.832748 1237.902100,241.625000 1241.840454,251.313889 
	C1253.920044,281.031891 1265.903320,310.789032 1278.048950,340.895782 
M1194.635742,280.082703 
	C1189.401123,294.687683 1184.166626,309.292664 1178.824707,324.197052 
	C1197.181763,324.197052 1214.988281,324.197052 1233.346924,324.197052 
	C1224.185425,299.157104 1215.201172,274.601501 1205.815063,248.947617 
	C1201.844238,259.992432 1198.366211,269.666168 1194.635742,280.082703 
z"/>
<path fill="#292929" opacity="1.000000" stroke="none" 
	d="
M687.823730,302.999939 
	C687.830322,278.026215 687.743958,253.551987 687.880615,229.079010 
	C687.954834,215.793060 696.079285,207.995346 708.388855,208.932510 
	C717.564697,209.631104 723.923523,216.085999 724.986023,225.872040 
	C725.182922,227.685852 725.153748,229.528992 725.154114,231.358627 
	C725.164795,280.139679 725.172363,328.920715 725.148438,377.701752 
	C725.147217,380.187134 725.168823,382.738586 724.652832,385.146301 
	C722.414490,395.590302 715.235596,400.806061 704.369812,400.124542 
	C695.486328,399.567322 688.623352,392.370819 688.009705,382.408783 
	C687.621338,376.104553 687.834106,369.761536 687.829346,363.435852 
	C687.814331,343.457214 687.823730,323.478607 687.823730,302.999939 
z"/>
<path fill="#284784" opacity="1.000000" stroke="none" 
	d="
M258.445770,350.749481 
	C259.972809,349.819824 261.499847,348.890137 263.779053,347.954559 
	C267.938660,348.506317 270.163239,347.269318 270.888977,343.758240 
	C270.888977,343.758240 270.899628,343.873383 271.207642,343.878906 
	C272.910461,342.768036 274.305298,341.651672 275.700134,340.535309 
	C275.700134,340.535309 275.731842,340.686615 276.019592,340.736328 
	C276.871368,340.191193 277.435364,339.596313 277.999390,339.001434 
	C277.999390,339.001434 278.000000,339.000000 278.359100,339.008728 
	C279.884644,339.479065 281.051086,339.940704 281.095428,339.958252 
	C283.865662,337.599243 286.002228,335.347717 288.542145,333.729156 
	C291.540009,331.818787 293.910736,329.896393 292.937592,325.906769 
	C292.937592,325.906769 292.989441,325.987610 293.329285,325.978729 
	C297.075012,325.168915 299.619904,323.737518 298.380920,319.535309 
	C298.380920,319.535309 298.487976,319.491882 298.904663,319.627075 
	C300.209473,319.835022 301.097595,319.907745 302.297333,320.144470 
	C304.886780,322.561432 304.074280,323.859131 301.458496,325.125793 
	C300.483063,325.598175 299.290771,327.978180 299.498322,328.185486 
	C302.732849,331.415253 299.683014,333.294647 297.770752,334.533264 
	C293.752441,337.136017 291.778900,339.980530 293.647095,344.886230 
	C293.987244,345.779388 292.733154,347.561188 291.828278,348.593903 
	C289.183380,351.612427 289.987061,353.221771 293.648254,354.098053 
	C296.679932,354.823669 299.678314,355.688263 302.691467,356.491302 
	C302.561829,357.125275 302.432190,357.759277 302.302551,358.393280 
	C299.863281,358.094421 297.412689,357.506348 294.988892,357.601440 
	C293.449280,357.661804 291.351318,358.311951 290.547974,359.440735 
	C288.437561,362.406097 288.720978,366.531433 290.554840,368.103058 
	C290.658447,388.353516 290.852386,408.604584 290.712280,428.853363 
	C290.699646,430.681427 288.532684,432.494568 286.542603,434.210144 
	C284.479340,434.067993 283.239655,434.030334 281.705200,433.697479 
	C277.766785,433.268188 274.124115,433.066864 270.479309,433.015533 
	C264.893127,432.936890 261.263947,428.566467 262.138611,423.040619 
	C262.576569,420.273773 262.942322,417.462067 262.961029,414.668121 
	C263.046967,401.839386 263.022369,389.009613 262.972015,376.180481 
	C262.964935,374.376404 263.089661,372.307800 262.290955,370.826691 
	C260.701508,367.879211 258.512146,365.255249 256.262390,362.059814 
	C257.991577,361.480988 259.760834,360.888733 261.991272,360.142120 
	C260.530701,357.061218 265.351318,352.013824 258.445770,350.749481 
z"/>
<path fill="#284D8B" opacity="1.000000" stroke="none" 
	d="
M258.220093,350.736572 
	C265.351318,352.013824 260.530701,357.061218 261.991272,360.142120 
	C259.760834,360.888733 257.991577,361.480988 256.262390,362.059814 
	C258.512146,365.255249 260.701508,367.879211 262.290955,370.826691 
	C263.089661,372.307800 262.964935,374.376404 262.972015,376.180481 
	C263.022369,389.009613 263.046967,401.839386 262.961029,414.668121 
	C262.942322,417.462067 262.576569,420.273773 262.138611,423.040619 
	C261.263947,428.566467 264.893127,432.936890 270.479309,433.015533 
	C274.124115,433.066864 277.766785,433.268188 281.236572,433.701355 
	C264.991150,434.054199 248.919617,434.107941 232.848083,434.161682 
	C232.848083,434.161713 232.990402,434.108582 232.841431,433.886902 
	C232.347336,433.563049 232.002228,433.460907 231.657120,433.358765 
	C231.657120,433.358734 231.636215,433.119812 231.711349,432.801819 
	C231.541183,414.591766 231.294632,396.699707 231.060669,378.807465 
	C231.056503,378.487305 231.123962,378.114441 231.291855,377.851715 
	C233.237488,374.806610 231.058563,373.927155 228.810593,373.075500 
	C228.810593,373.075500 228.347534,373.053711 227.940216,372.696960 
	C225.687775,371.559296 223.842667,370.778442 222.412643,369.997070 
	C225.533417,369.050232 228.593796,368.616486 230.866348,367.044098 
	C234.838745,364.295624 238.326889,360.847260 242.017944,357.692200 
	C242.017960,357.692200 242.170425,357.631958 242.675018,357.755249 
	C245.116074,357.585297 247.052536,357.292053 249.397369,356.999268 
	C254.395264,358.000549 255.124985,354.062531 256.896790,351.457886 
	C257.262665,351.213135 257.628540,350.968414 258.220093,350.736572 
z"/>
<path fill="#F3692E" opacity="1.000000" stroke="none" 
	d="
M271.533142,314.584900 
	C266.106079,321.795593 261.308411,329.614777 255.083115,336.053711 
	C248.223602,343.148590 240.108002,349.043701 232.405243,355.295929 
	C230.919724,356.501740 228.813812,356.943237 226.488129,357.358215 
	C226.292786,355.458466 226.403549,353.859283 226.962753,352.436340 
	C227.723022,350.501709 229.650482,348.701263 229.649292,346.844177 
	C229.641144,334.061005 229.233002,321.278503 229.018188,308.494720 
	C228.959793,305.018951 228.043488,301.504181 230.593552,298.213562 
	C231.379517,297.199341 230.407700,294.822968 230.240753,293.070282 
	C230.181351,292.283661 230.121948,291.497040 230.390961,290.002441 
	C230.813202,271.355072 231.022110,253.415054 230.927460,235.476654 
	C230.900711,230.405762 231.229614,226.346207 237.815262,226.778793 
	C238.178345,226.802658 238.604156,225.871201 239.000778,225.384399 
	C240.075638,225.314041 241.150513,225.243668 242.697418,225.575775 
	C243.395493,227.226761 243.832397,228.482086 243.792358,229.722000 
	C243.745789,231.163803 243.052368,232.588928 243.032379,234.026749 
	C242.696960,258.129364 242.350540,282.232452 242.236816,306.336548 
	C242.224106,309.027679 243.283203,311.877502 244.443268,314.376129 
	C245.542755,316.744324 247.404022,316.400330 248.389175,314.079926 
	C250.694702,308.649353 255.490570,307.620514 260.506317,307.045898 
	C262.643036,306.801117 264.830963,307.003326 267.326843,307.005554 
	C268.099335,307.010193 268.540619,307.011719 268.990234,307.006592 
	C268.998566,307.000000 268.987427,307.013092 268.994751,307.416748 
	C269.845795,310.075256 270.689453,312.330078 271.533142,314.584900 
z"/>
<path fill="#2A407B" opacity="1.000000" stroke="none" 
	d="
M290.626373,367.651184 
	C288.720978,366.531433 288.437561,362.406097 290.547974,359.440735 
	C291.351318,358.311951 293.449280,357.661804 294.988892,357.601440 
	C297.412689,357.506348 299.863281,358.094421 302.302551,358.393280 
	C302.432190,357.759277 302.561829,357.125275 302.691467,356.491302 
	C299.678314,355.688263 296.679932,354.823669 293.648254,354.098053 
	C289.987061,353.221771 289.183380,351.612427 291.828278,348.593903 
	C292.733154,347.561188 293.987244,345.779388 293.647095,344.886230 
	C291.778900,339.980530 293.752441,337.136017 297.770752,334.533264 
	C299.683014,333.294647 302.732849,331.415253 299.498322,328.185486 
	C299.290771,327.978180 300.483063,325.598175 301.458496,325.125793 
	C304.074280,323.859131 304.886780,322.561432 302.341858,319.770874 
	C302.652802,317.285767 303.230927,315.338287 303.809021,313.390808 
	C306.305573,309.745972 308.594360,305.927063 311.417816,302.556030 
	C312.335785,301.460083 314.645142,301.162476 316.328247,301.135498 
	C325.560974,300.987579 334.796875,301.037201 344.211060,301.341370 
	C342.374054,301.939178 340.357727,302.214600 337.375488,302.622009 
	C339.386749,304.248138 340.516205,305.161346 342.254028,306.566406 
	C340.661682,306.713013 339.864532,306.939453 339.115875,306.830994 
	C333.385376,306.001160 329.236847,310.926453 329.501678,316.493500 
	C329.949432,325.904602 330.046875,335.439972 329.018951,344.783020 
	C328.019531,353.866486 327.886993,354.509613 337.302521,354.992645 
	C338.133209,355.035248 339.022644,354.832428 339.786163,355.066010 
	C340.831604,355.385803 341.782532,356.014496 342.773987,356.510803 
	C342.669800,356.847382 342.565613,357.183929 342.461395,357.520508 
	C336.120178,358.333801 329.778992,359.147095 323.437775,359.960388 
	C323.527405,360.785645 323.617035,361.610870 323.706665,362.436127 
	C335.448425,362.436127 347.190186,362.436127 358.931946,362.436127 
	C357.141663,362.626556 355.351471,362.981201 353.561096,362.982208 
	C334.125977,362.992950 314.690308,363.014343 295.256256,362.854767 
	C291.943481,362.827606 290.060333,363.475922 290.626373,367.651184 
M306.360199,361.992889 
	C308.198914,361.992889 310.037659,361.992889 311.876373,361.992889 
	C311.869690,361.762604 311.862976,361.532288 311.856293,361.302002 
	C307.293060,361.302002 302.729858,361.302002 298.166626,361.302002 
	C298.166656,361.532166 298.166687,361.762329 298.166718,361.992493 
	C300.595276,361.992493 303.023834,361.992493 306.360199,361.992889 
M329.098236,304.524750 
	C329.893066,304.524750 330.687866,304.524750 331.482697,304.524750 
	C331.528229,304.196014 331.573761,303.867310 331.619293,303.538574 
	C330.285583,303.117401 328.951904,302.696259 327.618195,302.275085 
	C327.495422,302.523560 327.372650,302.772034 327.249847,303.020508 
	C327.673248,303.419525 328.096649,303.818542 329.098236,304.524750 
M335.373505,302.525330 
	C335.160583,302.470825 334.947632,302.416321 334.734711,302.361816 
	C334.871521,302.495880 335.008362,302.629913 335.373505,302.525330 
z"/>
<path fill="#283875" opacity="1.000000" stroke="none" 
	d="
M359.273560,362.414673 
	C347.190186,362.436127 335.448425,362.436127 323.706665,362.436127 
	C323.617035,361.610870 323.527405,360.785645 323.437775,359.960388 
	C329.778992,359.147095 336.120178,358.333801 342.461395,357.520508 
	C342.565613,357.183929 342.669800,356.847382 342.773987,356.510803 
	C341.782532,356.014496 340.831604,355.385803 339.786163,355.066010 
	C339.022644,354.832428 338.133209,355.035248 337.302521,354.992645 
	C327.886993,354.509613 328.019531,353.866486 329.018951,344.783020 
	C330.046875,335.439972 329.949432,325.904602 329.501678,316.493500 
	C329.236847,310.926453 333.385376,306.001160 339.115875,306.830994 
	C339.864532,306.939453 340.661682,306.713013 342.254028,306.566406 
	C340.516205,305.161346 339.386749,304.248138 337.375488,302.622009 
	C340.357727,302.214600 342.374054,301.939178 344.671539,301.414795 
	C349.108643,301.114807 353.264557,301.032043 357.420624,301.022156 
	C361.059509,301.013489 363.062012,302.918152 363.070862,306.522705 
	C363.112671,323.496674 363.165314,340.472321 362.907135,357.442657 
	C362.881805,359.109131 360.762512,360.743805 359.273560,362.414673 
z"/>
<path fill="#302E66" opacity="1.000000" stroke="none" 
	d="
M417.902344,194.042419 
	C419.550873,195.766754 421.199371,197.491104 423.025452,199.772919 
	C423.456909,200.555389 423.710785,200.780396 423.964661,201.005402 
	C435.708344,215.007828 442.112427,231.430420 444.986176,249.211807 
	C449.456635,276.872955 444.219727,303.389374 434.814850,329.389374 
	C428.471161,346.926605 420.370422,363.528137 409.421448,378.943085 
	C409.069611,376.678833 409.343964,374.522980 409.662659,372.018860 
	C407.674316,372.374847 406.275177,372.625305 404.876038,372.875793 
	C411.194183,360.276581 418.212128,347.973022 423.685638,335.016998 
	C435.316833,307.485718 440.934509,278.871429 435.948822,248.882294 
	C433.049713,231.444153 425.691010,216.059341 414.297485,201.977051 
	C415.530182,198.912140 416.716278,196.477280 417.902344,194.042419 
z"/>
<path fill="#2777B6" opacity="1.000000" stroke="none" 
	d="
M108.090393,378.443604 
	C96.201439,360.302124 86.163391,341.231293 78.518074,320.887299 
	C71.957970,303.431000 67.926086,285.605103 67.943504,266.834961 
	C67.967102,241.398010 76.799248,219.365814 92.894272,199.891449 
	C95.794731,198.972076 98.383827,197.967758 100.987259,196.980835 
	C101.001595,196.998215 101.036346,197.026901 100.991875,197.361282 
	C101.592972,199.142471 96.790871,200.531418 100.901520,202.015030 
	C97.058372,207.793152 93.078049,213.485397 89.397194,219.365128 
	C77.254837,238.761078 75.324707,260.145782 77.415581,282.274323 
	C78.996521,299.006073 85.459206,314.348511 90.764381,330.055237 
	C95.603287,344.381500 105.202240,356.025970 111.664734,369.809387 
	C111.744461,370.492950 111.947174,370.707031 112.149902,370.921112 
	C112.149902,370.921112 112.059784,370.947205 112.073532,371.258026 
	C114.104462,375.807770 108.424507,375.571686 108.090393,378.443604 
z"/>
<path fill="#ED3826" opacity="1.000000" stroke="none" 
	d="
M268.078796,225.905487 
	C273.735718,225.918030 279.392609,225.929718 285.049530,225.943375 
	C289.072021,225.953079 291.131927,227.737488 291.085175,231.993896 
	C290.948059,244.473190 291.756042,257.027039 290.618683,269.406982 
	C289.943542,276.755463 286.712006,284.000031 283.870026,290.982056 
	C280.959198,298.133270 277.121277,304.907104 273.264862,311.724426 
	C271.556213,310.072876 270.271820,308.542999 268.987427,307.013092 
	C268.987427,307.013092 268.998566,307.000000 269.041931,306.619446 
	C269.328613,304.792114 270.201569,303.072418 269.712097,301.943268 
	C266.750671,295.111664 267.847931,288.065002 267.940399,281.004517 
	C268.138733,265.865234 267.946228,250.721222 268.043549,235.579971 
	C268.065338,232.187988 268.882812,228.744919 266.358826,226.004913 
	C267.162048,225.964920 267.620422,225.935211 268.078796,225.905487 
z"/>
<path fill="#F8A541" opacity="1.000000" stroke="none" 
	d="
M188.882111,358.622559 
	C180.561768,358.690094 172.240829,358.847260 163.921341,358.790436 
	C159.179016,358.758026 157.458862,355.125031 157.446136,351.387970 
	C157.389984,334.911407 157.678375,318.432098 158.004608,301.957031 
	C158.066147,298.849152 160.090302,297.303925 163.338684,297.338226 
	C172.997330,297.440308 182.656982,297.445190 192.665543,297.967163 
	C193.006973,298.629242 192.999100,298.812225 192.524963,298.993073 
	C187.431992,298.990906 182.805298,298.990906 178.178589,298.990906 
	C178.173019,299.240540 178.167435,299.490173 178.161850,299.739807 
	C179.430817,299.921997 180.699799,300.104218 182.609482,300.378418 
	C181.002548,300.998505 180.146759,301.328735 179.997574,301.386322 
	C179.997574,306.648102 179.997543,311.349976 179.997589,316.051849 
	C179.997650,325.676422 179.924194,335.301758 180.018356,344.925385 
	C180.113968,354.697357 181.284393,356.389404 188.882111,358.622559 
z"/>
<path fill="#F05B2A" opacity="1.000000" stroke="none" 
	d="
M266.995636,307.002441 
	C264.830963,307.003326 262.643036,306.801117 260.506317,307.045898 
	C255.490570,307.620514 250.694702,308.649353 248.389175,314.079926 
	C247.404022,316.400330 245.542755,316.744324 244.443268,314.376129 
	C243.283203,311.877502 242.224106,309.027679 242.236816,306.336548 
	C242.350540,282.232452 242.696960,258.129364 243.032379,234.026749 
	C243.052368,232.588928 243.745789,231.163803 243.792358,229.722000 
	C243.832397,228.482086 243.395493,227.226761 243.092285,225.669189 
	C247.374100,225.286362 251.733139,225.212616 256.474121,225.547211 
	C256.020355,226.614227 255.184616,227.272919 254.914871,227.485519 
	C255.578064,229.199020 256.530273,230.509415 256.524567,231.815659 
	C256.427124,254.172256 256.104767,276.528473 256.129242,298.884430 
	C256.133179,302.489746 257.874481,305.547577 262.673920,305.119965 
	C264.047699,304.997620 265.551483,306.334625 266.995636,307.002441 
z"/>
<path fill="#F79236" opacity="1.000000" stroke="none" 
	d="
M189.351486,358.713043 
	C181.284393,356.389404 180.113968,354.697357 180.018356,344.925385 
	C179.924194,335.301758 179.997650,325.676422 179.997589,316.051849 
	C179.997543,311.349976 179.997574,306.648102 179.997574,301.386322 
	C180.146759,301.328735 181.002548,300.998505 182.609482,300.378418 
	C180.699799,300.104218 179.430817,299.921997 178.161850,299.739807 
	C178.167435,299.490173 178.173019,299.240540 178.178589,298.990906 
	C182.805298,298.990906 187.431992,298.990906 192.692078,299.308289 
	C195.102554,302.295593 198.412201,304.969543 198.405243,307.634796 
	C198.363510,323.606812 197.558823,339.576843 197.009232,355.566345 
	C202.848541,356.398010 202.848541,356.398010 204.000366,358.566101 
	C199.273865,358.645233 194.547363,358.724396 189.351486,358.713043 
z"/>
<path fill="#EE4A26" opacity="1.000000" stroke="none" 
	d="
M267.326843,307.005554 
	C265.551483,306.334625 264.047699,304.997620 262.673920,305.119965 
	C257.874481,305.547577 256.133179,302.489746 256.129242,298.884430 
	C256.104767,276.528473 256.427124,254.172256 256.524567,231.815659 
	C256.530273,230.509415 255.578064,229.199020 254.914871,227.485519 
	C255.184616,227.272919 256.020355,226.614227 256.930664,225.638779 
	C259.705078,225.250427 262.404907,225.178848 265.550079,225.364136 
	C265.995422,225.621002 266.013947,226.015213 266.013947,226.015213 
	C268.882812,228.744919 268.065338,232.187988 268.043549,235.579971 
	C267.946228,250.721222 268.138733,265.865234 267.940399,281.004517 
	C267.847931,288.065002 266.750671,295.111664 269.712097,301.943268 
	C270.201569,303.072418 269.328613,304.792114 269.033630,306.626038 
	C268.540619,307.011719 268.099335,307.010193 267.326843,307.005554 
z"/>
<path fill="#F4762F" opacity="1.000000" stroke="none" 
	d="
M230.106964,293.427551 
	C230.407700,294.822968 231.379517,297.199341 230.593552,298.213562 
	C228.043488,301.504181 228.959793,305.018951 229.018188,308.494720 
	C229.233002,321.278503 229.641144,334.061005 229.649292,346.844177 
	C229.650482,348.701263 227.723022,350.501709 226.962753,352.436340 
	C226.403549,353.859283 226.292786,355.458466 226.098602,357.376373 
	C225.294266,358.085419 224.373520,358.397186 222.886093,358.464447 
	C220.568985,358.390594 218.818558,358.561249 217.068115,358.731934 
	C215.981598,358.764862 214.895081,358.797760 213.401978,358.388000 
	C213.118240,355.504547 213.277435,353.065002 213.357849,350.622833 
	C213.797028,337.286041 214.220932,323.948700 214.632996,310.611053 
	C214.657486,309.818481 214.832367,308.554626 214.432404,308.298584 
	C210.445282,305.745758 212.794434,303.373932 214.355148,301.321472 
	C213.491898,299.987335 212.771118,298.873413 212.050354,297.759491 
	C215.851425,297.758698 219.695160,297.405212 223.441315,297.858398 
	C227.028625,298.292389 229.286957,297.549438 230.106964,293.427551 
z"/>
<path fill="#F58432" opacity="1.000000" stroke="none" 
	d="
M211.605774,297.591614 
	C212.771118,298.873413 213.491898,299.987335 214.355148,301.321472 
	C212.794434,303.373932 210.445282,305.745758 214.432404,308.298584 
	C214.832367,308.554626 214.657486,309.818481 214.632996,310.611053 
	C214.220932,323.948700 213.797028,337.286041 213.357849,350.622833 
	C213.277435,353.065002 213.118240,355.504547 213.005386,358.289032 
	C211.925491,358.703796 210.835632,358.774811 209.264053,358.630676 
	C208.477509,358.247955 208.237366,358.321320 208.061905,358.635529 
	C206.973297,358.681488 205.884674,358.727417 204.398224,358.669739 
	C202.848541,356.398010 202.848541,356.398010 197.009232,355.566345 
	C197.558823,339.576843 198.363510,323.606812 198.405243,307.634796 
	C198.412201,304.969543 195.102554,302.295593 193.158356,299.310455 
	C192.999100,298.812225 193.006973,298.629242 193.018280,298.171387 
	C199.068222,297.738892 205.114731,297.581299 211.605774,297.591614 
M203.520081,300.918762 
	C203.763641,300.774445 204.007202,300.630157 204.250748,300.485840 
	C203.999634,300.352264 203.687134,300.059265 203.513184,300.126221 
	C203.304169,300.206665 203.213104,300.593597 203.520081,300.918762 
z"/>
<path fill="#296AA9" opacity="1.000000" stroke="none" 
	d="
M108.213760,378.793640 
	C108.424507,375.571686 114.104462,375.807770 112.089813,371.214050 
	C114.744049,374.812195 117.640739,378.626740 119.975471,382.758789 
	C122.119652,386.553680 124.489304,388.703705 129.317902,388.638031 
	C134.264069,388.570740 139.356995,389.715759 142.005829,394.917755 
	C144.466949,399.751068 144.255997,404.523773 141.317657,409.097595 
	C138.137680,414.047485 132.377625,416.001984 126.508179,414.208649 
	C120.414772,412.346832 116.310707,407.412170 117.426956,401.141602 
	C118.411377,395.611664 117.092087,391.594269 113.677017,387.539337 
	C111.559494,385.025085 110.094833,381.960968 108.213760,378.793640 
z"/>
<path fill="#33396E" opacity="1.000000" stroke="none" 
	d="
M417.801636,193.723358 
	C416.716278,196.477280 415.530182,198.912140 414.175415,201.660568 
	C403.301727,190.773956 390.992432,181.993149 376.417877,176.425705 
	C364.006958,171.684784 351.224457,169.525208 337.770844,169.566315 
	C339.466309,168.518646 341.451782,167.028198 343.257812,167.220764 
	C348.429535,167.772141 353.515442,169.086609 358.664154,169.921890 
	C360.175568,170.167068 361.783966,169.814224 363.347931,169.735474 
	C363.513428,169.138351 363.678925,168.541214 363.844421,167.944092 
	C361.213226,166.962540 358.652374,165.624619 355.933350,165.088043 
	C352.389771,164.388718 348.689026,164.506531 345.124207,163.882568 
	C343.978912,163.682129 343.049988,162.245819 342.022064,161.374908 
	C348.715942,162.136108 355.476501,162.531982 362.089020,163.738983 
	C373.772247,165.871521 385.199951,169.270172 395.139465,175.893280 
	C403.040985,181.158417 410.207855,187.526093 417.801636,193.723358 
z"/>
<path fill="#33396E" opacity="1.000000" stroke="none" 
	d="
M404.898285,372.892639 
	C406.275177,372.625305 407.674316,372.374847 409.662659,372.018860 
	C409.343964,374.522980 409.069611,376.678833 409.098816,379.015717 
	C405.412903,385.419800 401.986267,392.106476 397.319550,397.771667 
	C386.814636,410.524261 375.695282,422.770721 364.469604,434.999756 
	C364.410248,433.284485 364.707245,431.792603 365.262238,429.004578 
	C361.967041,430.979187 359.497925,432.553833 356.939392,433.966553 
	C354.098083,435.535461 351.175201,436.956665 348.287872,438.442200 
	C356.599121,430.371674 365.532013,422.837280 373.068756,414.098724 
	C383.129517,402.433777 392.106049,389.833710 401.863800,377.741425 
	C406.488403,378.041626 404.443817,374.767700 404.901825,372.859741 
	C404.901825,372.859741 404.920502,372.909485 404.898285,372.892639 
z"/>
<path fill="#284D8B" opacity="1.000000" stroke="none" 
	d="
M301.971588,176.955994 
	C301.496185,177.270905 301.020782,177.585831 300.028076,177.640518 
	C298.658936,177.999252 297.807129,178.618240 296.955322,179.237213 
	C282.564758,186.390839 271.181580,197.101227 261.287292,209.534241 
	C258.935486,212.489441 257.369110,213.221634 254.542953,209.613052 
	C250.163483,204.021210 245.016251,199.030685 240.251495,193.199402 
	C241.530090,193.196884 242.844955,193.644608 243.946808,194.402130 
	C245.015778,195.137054 245.865982,196.190140 246.812271,197.103500 
	C247.389786,196.596954 247.967300,196.090424 248.544815,195.583878 
	C246.406845,192.975876 244.270187,190.366806 242.130554,187.760162 
	C239.892105,185.033127 237.650787,182.308441 235.410706,179.582733 
	C241.444626,184.868805 247.645950,189.978485 253.460510,195.495743 
	C258.274658,200.063751 259.965149,199.781616 263.798584,194.533936 
	C269.934967,186.133743 278.167114,180.000290 287.842651,175.265854 
	C292.644135,174.333160 296.861969,173.156937 301.117767,171.970139 
	C301.448029,173.898773 301.709808,175.427383 301.971588,176.955994 
z"/>
<path fill="#295C98" opacity="1.000000" stroke="none" 
	d="
M216.876373,369.589355 
	C217.000046,369.270691 217.229691,369.078979 218.118439,369.022339 
	C219.098450,369.041290 219.525299,369.052155 219.964294,369.301697 
	C219.976440,369.540405 219.993286,370.019440 219.987274,370.384705 
	C217.096054,373.279022 214.186874,375.781311 211.331390,378.343475 
	C207.237183,382.017120 202.046402,384.804169 200.981171,390.965607 
	C200.981171,390.965607 201.004074,390.995850 200.614853,391.041901 
	C199.526306,392.522766 198.826981,393.957581 198.127640,395.392365 
	C197.910126,395.849304 197.692627,396.306244 196.892090,396.934357 
	C195.209991,399.749817 194.110931,402.394135 193.011871,405.038422 
	C193.013962,405.210571 193.016052,405.382751 192.535065,405.859528 
	C191.619690,406.635010 190.927460,407.041870 190.793472,407.586212 
	C188.103928,418.511597 187.718918,429.495667 190.405914,440.468628 
	C190.649963,441.465240 192.109604,442.164154 193.004761,443.001343 
	C193.004745,443.001343 192.998962,442.999969 193.002197,443.390228 
	C193.360825,445.382965 193.427277,447.125732 194.124695,448.562164 
	C196.403549,453.255737 198.483612,458.218475 204.163116,459.948181 
	C204.494476,459.954529 204.749191,460.097443 205.008759,460.991028 
	C205.661499,464.553406 206.450287,466.966400 210.071304,467.909424 
	C212.022568,468.417542 213.441452,470.847290 215.189850,472.302795 
	C216.246063,473.182068 217.426102,473.942688 218.635132,474.601257 
	C220.403656,475.564545 222.248123,476.388458 224.060226,477.271759 
	C223.659195,478.078461 223.258163,478.885162 222.857132,479.691864 
	C219.424240,478.134064 216.022873,476.500763 212.545761,475.048859 
	C210.682907,474.270996 208.689682,473.805481 206.755280,473.199036 
	C206.755280,473.199036 206.883301,473.140961 206.879669,472.832275 
	C203.656387,469.986816 200.436722,467.450043 197.217072,464.913269 
	C197.217072,464.913269 197.071854,464.939392 197.055603,464.496094 
	C194.136139,460.030762 191.232941,456.008728 188.329742,451.986694 
	C188.168747,451.669586 188.007736,451.352478 187.887268,450.355225 
	C187.025253,447.782410 186.122696,445.889709 185.220139,443.997009 
	C185.137375,443.830322 185.054611,443.663666 184.993195,442.802368 
	C184.342789,434.727020 183.309158,427.354828 183.136246,419.962494 
	C183.033981,415.590759 184.292542,411.187225 184.939178,406.797974 
	C184.939178,406.797974 184.871292,406.376129 185.342072,406.115051 
	C186.487427,403.693848 187.162003,401.533691 187.836594,399.373535 
	C188.099899,398.705109 188.363174,398.036682 189.176407,397.133423 
	C192.104355,393.708008 194.558853,390.570740 196.833588,387.308136 
	C198.363602,385.113678 199.639404,382.741974 201.028931,380.449585 
	C201.028931,380.449585 201.164230,380.041260 201.748276,379.966064 
	C204.401215,379.012482 206.740982,378.491852 208.478821,377.176208 
	C211.475235,374.907776 214.097244,372.144775 216.876373,369.589355 
z"/>
<path fill="#284784" opacity="1.000000" stroke="none" 
	d="
M271.001709,478.026611 
	C277.363678,476.323303 283.725677,474.619995 290.843689,472.949585 
	C292.355927,472.546661 293.112122,472.110779 293.868317,471.674927 
	C293.868317,471.674927 293.897919,471.807587 294.203766,471.859314 
	C295.303101,471.511261 296.096558,471.111511 296.890045,470.711761 
	C296.890045,470.711761 296.904297,470.841675 297.263489,470.885803 
	C298.928986,470.824280 300.303528,470.944427 301.529633,470.573700 
	C306.629181,469.031708 311.683960,467.341827 317.356445,465.512268 
	C318.386871,461.032013 324.260925,457.876556 330.255341,461.178680 
	C326.448853,462.487305 323.671021,463.442322 320.691254,464.466736 
	C320.833893,467.659088 320.047485,468.677246 314.772430,469.975159 
	C311.220795,470.849030 308.298462,472.267731 306.979401,475.931000 
	C306.979401,475.931000 306.607269,476.074982 305.990295,476.049622 
	C302.326324,476.745392 299.234436,477.328796 296.267487,478.295654 
	C295.618225,478.507263 295.414459,480.085876 295.005524,481.034943 
	C288.446381,482.762604 281.887238,484.490234 274.549683,486.144348 
	C272.858246,486.303558 271.945221,486.536285 271.032166,486.768982 
	C269.779938,486.926056 268.527740,487.083130 266.457001,487.146667 
	C259.916840,484.794098 254.227097,487.160217 248.521118,487.171387 
	C248.175217,487.210052 247.829315,487.248718 247.212601,486.868164 
	C246.556976,486.098480 246.172165,485.748016 245.787369,485.397552 
	C245.532959,485.887329 245.278534,486.377106 245.024124,486.866882 
	C243.924927,486.864563 242.825714,486.862244 241.200531,486.556763 
	C240.423264,486.082336 240.152542,486.042297 239.862366,486.133453 
	C239.862366,486.133453 239.856766,486.120544 240.028610,485.794373 
	C241.479218,484.633301 242.694656,483.213104 244.047913,483.066650 
	C249.385666,482.489014 254.763168,482.217041 260.132843,482.015900 
	C264.239990,481.862030 268.357513,481.985291 272.870819,481.985291 
	C271.956696,480.049194 271.479187,479.037903 271.001709,478.026611 
M265.412872,485.483307 
	C265.412872,485.483307 265.524750,485.417450 265.412872,485.483307 
z"/>
<path fill="#344379" opacity="1.000000" stroke="none" 
	d="
M307.351929,475.952362 
	C308.298462,472.267731 311.220795,470.849030 314.772430,469.975159 
	C320.047485,468.677246 320.833893,467.659088 320.691254,464.466736 
	C323.671021,463.442322 326.448853,462.487305 330.255341,461.178680 
	C324.260925,457.876556 318.386871,461.032013 317.356445,465.512268 
	C311.683960,467.341827 306.629181,469.031708 301.529633,470.573700 
	C300.303528,470.944427 298.928986,470.824280 297.201782,470.864319 
	C310.636444,462.607330 324.492035,454.415924 338.898224,446.315857 
	C340.434143,445.434296 341.419525,444.461334 342.404877,443.488403 
	C342.404877,443.488403 342.509827,443.499573 342.942017,443.638367 
	C344.189850,442.775757 345.005524,441.774353 345.821198,440.772949 
	C345.821198,440.772949 345.908813,440.913452 346.248047,440.915833 
	C347.153015,440.104340 347.718750,439.290436 348.284485,438.476562 
	C348.284485,438.476532 348.318665,438.470917 348.303284,438.456543 
	C351.175201,436.956665 354.098083,435.535461 356.939392,433.966553 
	C359.497925,432.553833 361.967041,430.979187 365.262238,429.004578 
	C364.707245,431.792603 364.410248,433.284485 364.165405,435.167847 
	C363.957184,435.947174 363.696808,436.335022 363.064331,436.652405 
	C362.576691,436.846832 362.461121,437.111755 362.345551,437.376648 
	C362.345551,437.376648 362.235596,437.282471 362.002747,437.303284 
	C361.571350,437.656097 361.372772,437.988098 361.174194,438.320068 
	C361.174194,438.320068 361.181152,438.175323 360.986389,438.155334 
	C360.471954,438.157806 360.152222,438.180237 359.832520,438.202667 
	C360.123566,438.462708 360.414642,438.722748 360.705750,438.982788 
	C360.022064,439.651855 359.338379,440.320892 358.175934,440.809906 
	C357.139435,441.445251 356.581665,442.260651 356.023926,443.076050 
	C355.725525,443.414001 355.427124,443.751923 354.505371,444.089050 
	C351.871124,444.156006 349.745972,444.128723 351.312592,447.174713 
	C338.133759,458.818481 323.837799,468.771423 307.351929,475.952362 
z"/>
<path fill="#296AA9" opacity="1.000000" stroke="none" 
	d="
M152.569855,405.380188 
	C148.670456,420.085449 138.052460,427.349243 123.813293,423.291840 
	C111.114883,419.673462 104.967506,406.669464 109.548370,393.443878 
	C110.005852,393.354828 110.463341,393.265808 110.920822,393.176758 
	C111.311424,395.636627 112.051331,398.099396 112.030777,400.555817 
	C111.951469,410.033630 117.651451,417.751892 126.259117,419.440948 
	C137.391647,421.625458 147.231506,414.810272 148.820587,403.814697 
	C150.270996,393.778534 142.474991,384.259186 131.854309,383.006805 
	C130.096313,382.799469 128.377121,382.263092 126.030289,381.743896 
	C129.733414,378.334869 134.517090,378.519531 140.346893,381.526245 
	C149.517990,386.256287 153.764053,394.319275 152.569855,405.380188 
z"/>
<path fill="#344379" opacity="1.000000" stroke="none" 
	d="
M302.415955,176.901001 
	C301.709808,175.427383 301.448029,173.898773 301.117767,171.970139 
	C296.861969,173.156937 292.644135,174.333160 288.197693,175.226471 
	C298.589172,168.707520 310.068665,164.925827 322.873718,163.288055 
	C326.977600,163.409546 330.405518,163.318680 333.800903,162.938049 
	C334.906525,162.814117 335.927307,161.933899 336.986908,161.399597 
	C338.390564,161.323730 339.794250,161.247864 341.609985,161.273468 
	C343.049988,162.245819 343.978912,163.682129 345.124207,163.882568 
	C348.689026,164.506531 352.389771,164.388718 355.933350,165.088043 
	C358.652374,165.624619 361.213226,166.962540 363.844421,167.944092 
	C363.678925,168.541214 363.513428,169.138351 363.347931,169.735474 
	C361.783966,169.814224 360.175568,170.167068 358.664154,169.921890 
	C353.515442,169.086609 348.429535,167.772141 343.257812,167.220764 
	C341.451782,167.028198 339.466309,168.518646 337.318420,169.481873 
	C326.081360,170.004868 315.406342,171.956665 304.721375,175.925323 
	C304.350220,176.043488 304.471313,176.076279 304.471313,176.076294 
	C303.934326,176.332855 303.397308,176.589432 302.415955,176.901001 
z"/>
<path fill="#36588D" opacity="1.000000" stroke="none" 
	d="
M235.399475,179.601273 
	C237.650787,182.308441 239.892105,185.033127 242.130554,187.760162 
	C244.270187,190.366806 246.406845,192.975876 248.544815,195.583878 
	C247.967300,196.090424 247.389786,196.596954 246.812271,197.103500 
	C245.865982,196.190140 245.015778,195.137054 243.946808,194.402130 
	C242.844955,193.644608 241.530090,193.196884 240.100098,192.906769 
	C232.562607,188.456467 225.503403,183.211380 217.840698,179.087708 
	C206.485275,172.976776 194.007156,170.422684 181.254700,169.442795 
	C189.496170,169.321854 197.748169,168.269623 205.617966,171.573761 
	C206.405884,171.904572 207.747253,170.917191 208.831772,170.541565 
	C208.098236,169.702698 207.474304,168.276978 206.614105,168.116302 
	C198.577591,166.615295 190.489105,165.392975 182.451019,163.899658 
	C181.535156,163.729523 180.836212,162.391815 180.036682,161.595490 
	C197.360886,162.132843 213.447449,167.099228 229.019592,175.780518 
	C231.057343,178.029449 231.861359,181.291489 235.347046,179.629456 
	C235.347046,179.629456 235.388214,179.619827 235.399475,179.601273 
M216.165375,172.173035 
	C214.675201,171.792603 213.185013,171.412186 211.694839,171.031754 
	C211.449692,171.573990 211.204544,172.116241 210.959396,172.658478 
	C212.592010,173.431335 214.224609,174.204208 215.857239,174.977066 
	C216.175171,174.158340 216.493103,173.339615 216.165375,172.173035 
z"/>
<path fill="#36588D" opacity="1.000000" stroke="none" 
	d="
M270.541321,477.999908 
	C271.479187,479.037903 271.956696,480.049194 272.870819,481.985291 
	C268.357513,481.985291 264.239990,481.862030 260.132843,482.015900 
	C254.763168,482.217041 249.385666,482.489014 244.047913,483.066650 
	C242.694656,483.213104 241.479218,484.633301 240.021622,485.808472 
	C227.904556,484.691223 216.845108,480.893433 206.885101,473.510468 
	C208.689682,473.805481 210.682907,474.270996 212.545761,475.048859 
	C216.022873,476.500763 219.424240,478.134064 222.857132,479.691864 
	C223.258163,478.885162 223.659195,478.078461 224.060226,477.271759 
	C222.248123,476.388458 220.403656,475.564545 218.635132,474.601257 
	C217.426102,473.942688 216.246063,473.182068 215.189850,472.302795 
	C213.441452,470.847290 212.022568,468.417542 210.071304,467.909424 
	C206.450287,466.966400 205.661499,464.553406 205.074844,461.207977 
	C221.569168,475.454834 241.047989,480.325287 262.627350,478.339355 
	C265.102142,478.111572 267.595978,478.090240 270.541321,477.999908 
z"/>
<path fill="#296AA9" opacity="1.000000" stroke="none" 
	d="
M150.994171,171.977631 
	C139.909698,177.004700 128.825241,182.031769 117.246742,186.725388 
	C116.159920,184.300278 117.119690,181.352722 113.685219,180.827621 
	C122.556786,176.284744 131.428345,171.741867 140.935913,167.436981 
	C143.325165,168.368759 145.078415,169.062561 147.074570,169.852493 
	C147.383835,169.384583 147.621246,168.556229 148.074738,168.413254 
	C151.798325,167.239380 155.543839,166.102661 159.358917,165.331940 
	C159.588120,165.285645 160.469879,168.469666 161.047989,170.150543 
	C160.529770,170.369843 160.011566,170.589142 158.911407,170.535706 
	C155.884354,170.834534 153.439270,171.406082 150.994171,171.977631 
z"/>
<path fill="#295C98" opacity="1.000000" stroke="none" 
	d="
M161.493591,170.174240 
	C160.469879,168.469666 159.588120,165.285645 159.358917,165.331940 
	C155.543839,166.102661 151.798325,167.239380 148.074738,168.413254 
	C147.621246,168.556229 147.383835,169.384583 147.074570,169.852493 
	C145.078415,169.062561 143.325165,168.368759 141.309250,167.444031 
	C153.425934,163.173325 166.089264,160.954742 179.592361,161.434021 
	C180.836212,162.391815 181.535156,163.729523 182.451019,163.899658 
	C190.489105,165.392975 198.577591,166.615295 206.614105,168.116302 
	C207.474304,168.276978 208.098236,169.702698 208.831772,170.541565 
	C207.747253,170.917191 206.405884,171.904572 205.617966,171.573761 
	C197.748169,168.269623 189.496170,169.321854 180.854706,169.285492 
	C180.359024,169.410187 180.179962,169.542526 180.054108,169.096100 
	C179.400879,168.175507 178.873520,167.701340 178.346146,167.227188 
	C177.942169,167.977188 177.538177,168.727188 177.134186,169.477173 
	C175.952759,169.567902 174.771332,169.658630 173.000763,169.487778 
	C171.295746,169.373474 170.179840,169.520767 169.063950,169.668045 
	C166.689026,169.844681 164.314102,170.021301 161.493591,170.174240 
z"/>
<path fill="#295C98" opacity="1.000000" stroke="none" 
	d="
M113.371750,180.946152 
	C117.119690,181.352722 116.159920,184.300278 116.884262,186.695038 
	C116.138519,187.838333 115.261246,188.678528 113.977921,189.894806 
	C113.418495,190.578857 113.265121,190.886841 113.111740,191.194824 
	C113.037239,191.356888 112.962730,191.518936 112.319641,191.817230 
	C110.843697,192.650238 109.936325,193.346985 109.028961,194.043732 
	C108.127937,194.923828 107.226906,195.803925 105.871460,196.969803 
	C105.195076,197.459885 105.065079,197.711700 105.027054,198.011017 
	C104.187180,198.991623 103.347305,199.972244 101.979172,201.044434 
	C101.204613,201.300476 101.044312,201.526047 100.969994,201.812759 
	C100.969994,201.812759 101.024940,201.943680 100.963226,201.979355 
	C96.790871,200.531418 101.592972,199.142471 101.126312,197.297028 
	C101.194458,196.920059 101.083687,196.941742 100.972916,196.963440 
	C98.383827,197.967758 95.794731,198.972076 93.138901,199.699921 
	C98.465256,191.922424 104.893372,185.548187 113.371750,180.946152 
z"/>
<path fill="#36588D" opacity="1.000000" stroke="none" 
	d="
M228.937332,373.528198 
	C231.058563,373.927155 233.237488,374.806610 231.291855,377.851715 
	C231.123962,378.114441 231.056503,378.487305 231.060669,378.807465 
	C231.294632,396.699707 231.541183,414.591766 231.698822,432.921082 
	C230.953552,431.314392 229.744949,429.275360 229.726746,427.225739 
	C229.597000,412.610687 229.750336,397.993439 229.714005,383.377106 
	C229.706223,380.244507 229.291397,377.112915 228.937332,373.528198 
z"/>
<path fill="#36588D" opacity="1.000000" stroke="none" 
	d="
M219.952148,369.063019 
	C219.525299,369.052155 219.098450,369.041290 218.351135,369.025330 
	C225.764709,365.245239 233.498734,361.470276 241.625351,357.693726 
	C238.326889,360.847260 234.838745,364.295624 230.866348,367.044098 
	C228.593796,368.616486 225.533417,369.050232 222.413864,369.998291 
	C222.000000,370.000000 222.003021,370.002045 221.832932,369.752319 
	C221.092606,369.356049 220.522385,369.209534 219.952148,369.063019 
z"/>
<path fill="#F4762F" opacity="1.000000" stroke="none" 
	d="
M238.593781,225.291901 
	C238.604156,225.871201 238.178345,226.802658 237.815262,226.778793 
	C231.229614,226.346207 230.900711,230.405762 230.927460,235.476654 
	C231.022110,253.415054 230.813202,271.355072 230.467224,289.612823 
	C230.092743,270.165924 229.889847,250.400635 229.899689,230.635468 
	C229.902451,225.082733 234.287003,225.293030 238.593781,225.291901 
z"/>
<path fill="#36588D" opacity="1.000000" stroke="none" 
	d="
M201.268555,390.801331 
	C202.046402,384.804169 207.237183,382.017120 211.331390,378.343475 
	C214.186874,375.781311 217.096054,373.279022 220.322266,370.378967 
	C221.109863,370.005981 221.556442,370.003998 222.003021,370.002045 
	C222.003021,370.002045 222.000000,370.000000 221.998779,369.998779 
	C223.842667,370.778442 225.687775,371.559296 227.746796,372.569214 
	C220.916733,376.982208 213.798935,381.049255 206.874191,385.422028 
	C204.822052,386.717957 203.313568,388.874756 201.268555,390.801331 
z"/>
<path fill="#36588D" opacity="1.000000" stroke="none" 
	d="
M192.996994,442.599915 
	C192.109604,442.164154 190.649963,441.465240 190.405914,440.468628 
	C187.718918,429.495667 188.103928,418.511597 190.793472,407.586212 
	C190.927460,407.041870 191.619690,406.635010 192.399261,406.079193 
	C189.095779,418.087341 188.719543,430.158508 192.996994,442.599915 
z"/>
<path fill="#36588D" opacity="1.000000" stroke="none" 
	d="
M233.137695,434.482544 
	C248.919617,434.107941 264.991150,434.054199 281.531342,433.996552 
	C283.239655,434.030334 284.479340,434.067993 286.181824,434.189270 
	C285.552582,434.538940 284.459961,435.038605 283.368561,435.036072 
	C266.721375,434.997131 250.074356,434.892609 233.137695,434.482544 
z"/>
<path fill="#344379" opacity="1.000000" stroke="none" 
	d="
M292.627808,326.036377 
	C293.910736,329.896393 291.540009,331.818787 288.542145,333.729156 
	C286.002228,335.347717 283.865662,337.599243 281.095428,339.958252 
	C281.051086,339.940704 279.884644,339.479065 278.360046,339.008728 
	C282.773926,334.721985 287.545959,330.443970 292.627808,326.036377 
z"/>
<path fill="#36588D" opacity="1.000000" stroke="none" 
	d="
M184.698776,407.086487 
	C184.292542,411.187225 183.033981,415.590759 183.136246,419.962494 
	C183.309158,427.354828 184.342789,434.727020 185.018799,442.524414 
	C180.609818,431.152863 180.661392,419.293671 184.698776,407.086487 
z"/>
<path fill="#36588D" opacity="1.000000" stroke="none" 
	d="
M200.743027,380.575409 
	C199.639404,382.741974 198.363602,385.113678 196.833588,387.308136 
	C194.558853,390.570740 192.104355,393.708008 189.385513,396.953674 
	C192.848831,391.572876 196.652985,386.137054 200.743027,380.575409 
z"/>
<path fill="#36588D" opacity="1.000000" stroke="none" 
	d="
M216.515991,369.679993 
	C214.097244,372.144775 211.475235,374.907776 208.478821,377.176208 
	C206.740982,378.491852 204.401215,379.012482 201.940369,379.869385 
	C206.417496,376.488770 211.286575,373.129700 216.515991,369.679993 
z"/>
<path fill="#36588D" opacity="1.000000" stroke="none" 
	d="
M204.035782,459.629852 
	C198.483612,458.218475 196.403549,453.255737 194.124695,448.562164 
	C193.427277,447.125732 193.360825,445.382965 193.003159,443.386017 
	C196.636734,448.431549 200.272598,453.871521 204.035782,459.629852 
z"/>
<path fill="#36588D" opacity="1.000000" stroke="none" 
	d="
M188.366959,452.325958 
	C191.232941,456.008728 194.136139,460.030762 197.062210,464.422607 
	C194.191437,460.750031 191.297791,456.707611 188.366959,452.325958 
z"/>
<path fill="#33396E" opacity="1.000000" stroke="none" 
	d="
M336.558228,161.319611 
	C335.927307,161.933899 334.906525,162.814117 333.800903,162.938049 
	C330.405518,163.318680 326.977600,163.409546 323.297729,163.342255 
	C327.398590,162.460861 331.764069,161.850250 336.558228,161.319611 
z"/>
<path fill="#36588D" opacity="1.000000" stroke="none" 
	d="
M197.352081,465.210480 
	C200.436722,467.450043 203.656387,469.986816 206.846146,472.894043 
	C203.706528,470.678925 200.596817,468.093323 197.352081,465.210480 
z"/>
<path fill="#284784" opacity="1.000000" stroke="none" 
	d="
M256.520813,351.527832 
	C255.124985,354.062531 254.395264,358.000549 249.400406,356.661407 
	C248.986755,355.872742 248.978394,355.422394 248.970032,354.972076 
	C251.361633,353.847290 253.753220,352.722504 256.520813,351.527832 
z"/>
<path fill="#344379" opacity="1.000000" stroke="none" 
	d="
M295.387421,481.040466 
	C295.414459,480.085876 295.618225,478.507263 296.267487,478.295654 
	C299.234436,477.328796 302.326324,476.745392 305.790863,476.045807 
	C302.728699,477.726929 299.248993,479.386475 295.387421,481.040466 
z"/>
<path fill="#36588D" opacity="1.000000" stroke="none" 
	d="
M248.880676,487.445740 
	C254.227097,487.160217 259.916840,484.794098 266.079224,487.112793 
	C260.760040,487.355011 255.000137,487.537567 248.880676,487.445740 
z"/>
<path fill="#344379" opacity="1.000000" stroke="none" 
	d="
M298.120148,319.703308 
	C299.619904,323.737518 297.075012,325.168915 293.288147,325.953918 
	C294.557922,323.915802 296.208649,321.893555 298.120148,319.703308 
z"/>
<path fill="#344379" opacity="1.000000" stroke="none" 
	d="
M303.520020,313.585938 
	C303.230927,315.338287 302.652802,317.285767 302.030212,319.606873 
	C301.097595,319.907745 300.209473,319.835022 298.910828,319.569641 
	C300.077179,317.511688 301.654114,315.646393 303.520020,313.585938 
z"/>
<path fill="#344379" opacity="1.000000" stroke="none" 
	d="
M270.539215,343.807312 
	C270.163239,347.269318 267.938660,348.506317 264.110901,347.914124 
	C265.856903,346.538483 268.023163,345.197418 270.539215,343.807312 
z"/>
<path fill="#36588D" opacity="1.000000" stroke="none" 
	d="
M193.271820,404.817566 
	C194.110931,402.394135 195.209991,399.749817 196.662338,397.054047 
	C195.854324,399.533936 194.693054,402.065308 193.271820,404.817566 
z"/>
<path fill="#EE4A26" opacity="1.000000" stroke="none" 
	d="
M268.994751,307.416748 
	C270.271820,308.542999 271.556213,310.072876 272.977661,311.920349 
	C273.114685,312.237885 273.065521,312.696991 272.597198,312.886841 
	C271.953064,313.540833 271.777222,314.004974 271.601410,314.469147 
	C271.601410,314.469147 271.569336,314.527893 271.551208,314.556396 
	C270.689453,312.330078 269.845795,310.075256 268.994751,307.416748 
z"/>
<path fill="#284D8B" opacity="1.000000" stroke="none" 
	d="
M235.171677,179.352692 
	C231.861359,181.291489 231.057343,178.029449 229.222961,176.043884 
	C231.045181,176.831970 233.020737,177.953964 235.171677,179.352692 
z"/>
<path fill="#302E66" opacity="1.000000" stroke="none" 
	d="
M404.611145,373.022766 
	C404.443817,374.767700 406.488403,378.041626 402.121216,377.546082 
	C402.815521,375.891632 403.567993,374.538696 404.611145,373.022766 
z"/>
<path fill="#36588D" opacity="1.000000" stroke="none" 
	d="
M248.601608,354.988129 
	C248.978394,355.422394 248.986755,355.872742 248.992065,356.660919 
	C247.052536,357.292053 245.116074,357.585297 242.745789,357.796631 
	C244.285706,356.811188 246.259445,355.907684 248.601608,354.988129 
z"/>
<path fill="#295C98" opacity="1.000000" stroke="none" 
	d="
M151.400269,172.010529 
	C153.439270,171.406082 155.884354,170.834534 158.620850,170.473511 
	C156.543625,171.137161 154.174988,171.590286 151.400269,172.010529 
z"/>
<path fill="#36588D" opacity="1.000000" stroke="none" 
	d="
M187.547729,399.611816 
	C187.162003,401.533691 186.487427,403.693848 185.451416,405.931519 
	C185.812943,403.956055 186.535919,401.903076 187.547729,399.611816 
z"/>
<path fill="#36588D" opacity="1.000000" stroke="none" 
	d="
M185.219269,444.350830 
	C186.122696,445.889709 187.025253,447.782410 187.892578,450.085083 
	C186.977676,448.564911 186.098038,446.634796 185.219269,444.350830 
z"/>
<path fill="#33396E" opacity="1.000000" stroke="none" 
	d="
M351.618652,447.065491 
	C349.745972,444.128723 351.871124,444.156006 354.273376,444.124451 
	C353.751373,445.092560 352.838013,446.024414 351.618652,447.065491 
z"/>
<path fill="#36588D" opacity="1.000000" stroke="none" 
	d="
M198.413818,395.211609 
	C198.826981,393.957581 199.526306,392.522766 200.597137,391.036194 
	C200.212418,392.333221 199.456207,393.682037 198.413818,395.211609 
z"/>
<path fill="#33396E" opacity="1.000000" stroke="none" 
	d="
M342.124451,443.598450 
	C341.419525,444.461334 340.434143,445.434296 339.221405,446.205566 
	C339.944031,445.238739 340.894043,444.473633 342.124451,443.598450 
z"/>
<path fill="#344379" opacity="1.000000" stroke="none" 
	d="
M275.372009,340.605377 
	C274.305298,341.651672 272.910461,342.768036 271.153320,343.859100 
	C272.208618,342.781006 273.626221,341.728210 275.372009,340.605377 
z"/>
<path fill="#36588D" opacity="1.000000" stroke="none" 
	d="
M177.487427,169.567261 
	C177.538177,168.727188 177.942169,167.977188 178.346146,167.227188 
	C178.873520,167.701340 179.400879,168.175507 179.959686,169.037109 
	C179.274292,169.502151 178.557480,169.579742 177.487427,169.567261 
z"/>
<path fill="#33396E" opacity="1.000000" stroke="none" 
	d="
M345.530884,440.876740 
	C345.005524,441.774353 344.189850,442.775757 342.980103,443.601929 
	C343.470886,442.611267 344.355743,441.795898 345.530884,440.876740 
z"/>
<path fill="#296AA9" opacity="1.000000" stroke="none" 
	d="
M109.321045,193.972321 
	C109.936325,193.346985 110.843697,192.650238 112.084198,191.944275 
	C111.482597,192.590332 110.547867,193.245621 109.321045,193.972321 
z"/>
<path fill="#F8A541" opacity="1.000000" stroke="none" 
	d="
M217.473419,358.843201 
	C218.818558,358.561249 220.568985,358.390594 222.589020,358.420044 
	C221.198654,358.731598 219.538696,358.843018 217.473419,358.843201 
z"/>
<path fill="#344379" opacity="1.000000" stroke="none" 
	d="
M297.290070,179.196442 
	C297.807129,178.618240 298.658936,177.999252 299.736786,177.620056 
	C299.183502,178.291779 298.404175,178.723724 297.290070,179.196442 
z"/>
<path fill="#36588D" opacity="1.000000" stroke="none" 
	d="
M245.193192,487.003571 
	C245.278534,486.377106 245.532959,485.887329 245.787369,485.397552 
	C246.172165,485.748016 246.556976,486.098480 246.975845,486.745819 
	C246.460693,487.075256 245.911469,487.107758 245.193192,487.003571 
z"/>
<path fill="#33396E" opacity="1.000000" stroke="none" 
	d="
M348.030670,438.614288 
	C347.718750,439.290436 347.153015,440.104340 346.180054,440.868591 
	C346.440857,440.129974 347.108826,439.440979 348.030670,438.614288 
z"/>
<path fill="#33396E" opacity="1.000000" stroke="none" 
	d="
M356.307068,442.993347 
	C356.581665,442.260651 357.139435,441.445251 357.888489,440.886780 
	C357.583282,441.732727 357.086761,442.321686 356.307068,442.993347 
z"/>
<path fill="#344379" opacity="1.000000" stroke="none" 
	d="
M277.701599,339.038269 
	C277.435364,339.596313 276.871368,340.191193 275.961304,340.685547 
	C276.211456,340.081726 276.807648,339.578400 277.701599,339.038269 
z"/>
<path fill="#36588D" opacity="1.000000" stroke="none" 
	d="
M169.444183,169.744812 
	C170.179840,169.520767 171.295746,169.373474 172.652451,169.399277 
	C171.870316,169.655426 170.847382,169.738495 169.444183,169.744812 
z"/>
<path fill="#344379" opacity="1.000000" stroke="none" 
	d="
M293.534485,471.705200 
	C293.112122,472.110779 292.355927,472.546661 291.233093,472.970703 
	C291.644501,472.551086 292.422577,472.143311 293.534485,471.705200 
z"/>
<path fill="#344379" opacity="1.000000" stroke="none" 
	d="
M296.546722,470.722656 
	C296.096558,471.111511 295.303101,471.511261 294.138245,471.841248 
	C294.579041,471.425537 295.391205,471.079559 296.546722,470.722656 
z"/>
<path fill="#36588D" opacity="1.000000" stroke="none" 
	d="
M271.389282,486.868103 
	C271.945221,486.536285 272.858246,486.303558 274.143921,486.132202 
	C273.593201,486.451477 272.669800,486.709351 271.389282,486.868103 
z"/>
<path fill="#F4762F" opacity="1.000000" stroke="none" 
	d="
M266.358826,226.004913 
	C266.013947,226.015213 265.995422,225.621002 265.996613,225.423859 
	C266.597961,225.265335 267.198090,225.303955 267.938538,225.624039 
	C267.620422,225.935211 267.162048,225.964920 266.358826,226.004913 
z"/>
<path fill="#33396E" opacity="1.000000" stroke="none" 
	d="
M360.922485,438.855713 
	C360.414642,438.722748 360.123566,438.462708 359.832520,438.202667 
	C360.152222,438.180237 360.471954,438.157806 361.047363,438.194458 
	C361.248474,438.411926 361.193848,438.570282 360.922485,438.855713 
z"/>
<path fill="#F3692E" opacity="1.000000" stroke="none" 
	d="
M271.829437,314.379028 
	C271.777222,314.004974 271.953064,313.540833 272.421997,313.037170 
	C272.495911,313.428101 272.276703,313.858521 271.829437,314.379028 
z"/>
<path fill="#296AA9" opacity="1.000000" stroke="none" 
	d="
M113.377762,191.098145 
	C113.265121,190.886841 113.418495,190.578857 113.805496,190.147110 
	C113.907333,190.349380 113.775551,190.675430 113.377762,191.098145 
z"/>
<path fill="#F8A541" opacity="1.000000" stroke="none" 
	d="
M208.279022,358.719055 
	C208.237366,358.321320 208.477509,358.247955 208.861511,358.540283 
	C208.940659,358.665070 208.496155,358.802612 208.279022,358.719055 
z"/>
<path fill="#36588D" opacity="1.000000" stroke="none" 
	d="
M239.976685,486.324036 
	C240.152542,486.042297 240.423264,486.082336 240.811905,486.413513 
	C240.949280,486.573364 240.512497,486.655731 240.512497,486.655731 
	C240.512497,486.655731 240.091019,486.514648 239.976685,486.324036 
z"/>
<path fill="#296AA9" opacity="1.000000" stroke="none" 
	d="
M112.088318,370.691223 
	C111.947174,370.707031 111.744461,370.492950 111.677460,370.157410 
	C111.813171,370.035980 112.026741,370.461365 112.088318,370.691223 
z"/>
<path fill="#284D8B" opacity="1.000000" stroke="none" 
	d="
M304.437561,176.032852 
	C304.471313,176.076279 304.350220,176.043488 304.317322,176.031799 
	C304.284393,176.020096 304.403778,175.989426 304.437561,176.032852 
z"/>
<path fill="#36588D" opacity="1.000000" stroke="none" 
	d="
M231.760071,433.612427 
	C232.002228,433.460907 232.347336,433.563049 232.784149,433.936768 
	C232.538223,434.094269 232.200623,433.980164 231.760071,433.612427 
z"/>
<path fill="#2777B6" opacity="1.000000" stroke="none" 
	d="
M101.138733,201.700806 
	C101.044312,201.526047 101.204613,201.300476 101.647644,201.060486 
	C101.694450,201.212082 101.515488,201.413376 101.138733,201.700806 
z"/>
<path fill="#296AA9" opacity="1.000000" stroke="none" 
	d="
M105.252258,197.939148 
	C105.065079,197.711700 105.195076,197.459885 105.602043,197.144974 
	C105.884666,197.386658 105.781471,197.664291 105.252258,197.939148 
z"/>
<path fill="#33396E" opacity="1.000000" stroke="none" 
	d="
M423.923157,200.740280 
	C423.710785,200.780396 423.456909,200.555389 423.137939,200.079651 
	C423.342407,200.044327 423.612000,200.259735 423.923157,200.740280 
z"/>
<path fill="#33396E" opacity="1.000000" stroke="none" 
	d="
M362.558380,437.310608 
	C362.461121,437.111755 362.576691,436.846832 362.831665,436.714111 
	C362.971100,436.846283 362.771210,437.244568 362.558380,437.310608 
z"/>
<path fill="#33396E" opacity="1.000000" stroke="none" 
	d="
M361.455933,438.249451 
	C361.372772,437.988098 361.571350,437.656097 362.073639,437.288635 
	C362.164124,437.561707 361.950928,437.870270 361.455933,438.249451 
z"/>
<path fill="#FEFEFE" opacity="1.000000" stroke="none" 
	d="
M805.003235,309.000000 
	C805.003235,286.520233 805.003235,264.540497 805.003235,241.838516 
	C809.433960,241.838516 813.690552,241.653656 817.926025,241.874252 
	C829.207458,242.461823 840.543335,242.696609 851.740540,244.043823 
	C867.410095,245.929123 877.491394,255.729568 882.211487,270.117615 
	C889.964233,293.749908 889.716370,317.724854 880.819641,341.050507 
	C874.528687,357.544495 861.907166,366.423615 844.050293,366.854645 
	C831.247803,367.163666 818.431824,366.914734 805.003235,366.914734 
	C805.003235,347.682068 805.003235,328.591034 805.003235,309.000000 
z"/>
<path fill="#FCFCFC" opacity="1.000000" stroke="none" 
	d="
M1014.436890,264.429291 
	C1016.221924,259.546722 1017.835083,255.017624 1020.005371,248.924316 
	C1029.422974,274.664185 1038.365234,299.104980 1047.511841,324.104156 
	C1029.227417,324.104156 1011.413025,324.104156 993.092590,324.104156 
	C1000.238342,304.083008 1007.251648,284.432892 1014.436890,264.429291 
z"/>
<path fill="#FCFCFC" opacity="1.000000" stroke="none" 
	d="
M1194.761963,279.711304 
	C1198.366211,269.666168 1201.844238,259.992432 1205.815063,248.947617 
	C1215.201172,274.601501 1224.185425,299.157104 1233.346924,324.197052 
	C1214.988281,324.197052 1197.181763,324.197052 1178.824707,324.197052 
	C1184.166626,309.292664 1189.401123,294.687683 1194.761963,279.711304 
z"/>
<path fill="#283875" opacity="1.000000" stroke="none" 
	d="
M305.906311,361.992676 
	C303.023834,361.992493 300.595276,361.992493 298.166718,361.992493 
	C298.166687,361.762329 298.166656,361.532166 298.166626,361.302002 
	C302.729858,361.302002 307.293060,361.302002 311.856293,361.302002 
	C311.862976,361.532288 311.869690,361.762604 311.876373,361.992889 
	C310.037659,361.992889 308.198914,361.992889 305.906311,361.992676 
z"/>
<path fill="#283875" opacity="1.000000" stroke="none" 
	d="
M328.809143,304.371155 
	C328.096649,303.818542 327.673248,303.419525 327.249847,303.020508 
	C327.372650,302.772034 327.495422,302.523560 327.618195,302.275085 
	C328.951904,302.696259 330.285583,303.117401 331.619293,303.538574 
	C331.573761,303.867310 331.528229,304.196014 331.482697,304.524750 
	C330.687866,304.524750 329.893066,304.524750 328.809143,304.371155 
z"/>
<path fill="#283875" opacity="1.000000" stroke="none" 
	d="
M335.259338,302.644653 
	C335.008362,302.629913 334.871521,302.495880 334.734711,302.361816 
	C334.947632,302.416321 335.160583,302.470825 335.259338,302.644653 
z"/>
<path fill="#F79236" opacity="1.000000" stroke="none" 
	d="
M203.295532,300.883301 
	C203.213104,300.593597 203.304169,300.206665 203.513184,300.126221 
	C203.687134,300.059265 203.999634,300.352264 204.250763,300.485840 
	C204.007202,300.630157 203.763641,300.774445 203.295532,300.883301 
z"/>
<path fill="#284D8B" opacity="1.000000" stroke="none" 
	d="
M221.832932,369.752319 
	C221.556442,370.003998 221.109863,370.005981 220.328278,370.013702 
	C219.993286,370.019440 219.976440,369.540405 219.964294,369.301697 
	C220.522385,369.209534 221.092606,369.356049 221.832932,369.752319 
z"/>
<path fill="#36588D" opacity="1.000000" stroke="none" 
	d="
M265.468811,485.450378 
	C265.524750,485.417450 265.412872,485.483307 265.468811,485.450378 
z"/>
<path fill="#295C98" opacity="1.000000" stroke="none" 
	d="
M216.488205,172.346954 
	C216.493103,173.339615 216.175171,174.158340 215.857239,174.977066 
	C214.224609,174.204208 212.592010,173.431335 210.959396,172.658478 
	C211.204544,172.116241 211.449692,171.573990 211.694839,171.031754 
	C213.185013,171.412186 214.675201,171.792603 216.488205,172.346954 
z"/>
<path fill="#2777B6" opacity="1.000000" stroke="none" 
	d="
M100.987259,196.980820 
	C101.083687,196.941742 101.194458,196.920059 101.170792,196.962646 
	C101.036346,197.026901 101.001595,196.998215 100.987259,196.980820 
z"/>
</svg>
  )
}

export default Logo
