import React from 'react';
import { messaging } from '../../config/firebase';

const Advertisement = () => {

  const handleSendNotification = async () => {
    try {
      // Get the current FCM token
      const currentToken = await messaging.getToken({ vapidKey: 'YOUR_VAPID_KEY_HERE' });
      
      if (currentToken) {
        console.log('FCM Registration Token:', currentToken);

        // Send the token to your backend to send the notification
        const response = await fetch('http://localhost:5000/send-notification', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            token: currentToken,
            title: 'New Advertisement!',
            body: 'Check out the latest advertisement on our app.',
          }),
        });

        if (response.ok) {
          console.log('Notification sent successfully!');
        } else {
          console.error('Failed to send notification');
        }
      } else {
        console.error('No registration token available. Request permission to generate one.');
      }
    } catch (err) {
      console.error('An error occurred while retrieving token or sending notification.', err);
    }
  };

  return (
    <div>
      <button onClick={handleSendNotification}>Send Notification</button>
    </div>
  );
};

export default Advertisement;
