import React from 'react';

const Footer = () => {
  return (
    <footer className="bg-blue-900 text-white py-10">
      <div className="container mx-auto grid grid-cols-2 md:grid-cols-4 gap-8">
        {/* Section 1: Nidaan */}
        <div>
          <h3 className="font-semibold mb-4">Nidaan</h3>
          <ul>
            <li className="mb-2"><a href="#" className="hover:underline">About</a></li>
            <li className="mb-2"><a href="#" className="hover:underline">Blog</a></li>
            <li className="mb-2"><a href="#" className="hover:underline">Careers</a></li>
            <li><a href="#" className="hover:underline">Contact Us</a></li>
          </ul>
        </div>

        {/* Section 2: For Patients */}
        <div>
          <h3 className="font-semibold mb-4">For Patients</h3>
          <ul>
            <li className="mb-2"><a href="#" className="hover:underline">Search for doctors</a></li>
            <li className="mb-2"><a href="#" className="hover:underline">Search for clinics</a></li>
            <li className="mb-2"><a href="#" className="hover:underline">Search for hospitals</a></li>
            <li className="mb-2"><a href="#" className="hover:underline">Health app</a></li>
          </ul>
        </div>

        {/* Section 3: For Doctors */}
        <div>
          <h3 className="font-semibold mb-4">For Doctors</h3>
          <ul>
            <li className="mb-2"><a href="#" className="hover:underline">Nidaan Plus</a></li>
            
          </ul>
        </div>

        {/* Section 4: More */}
        <div>
          <h3 className="font-semibold mb-4">More</h3>
          <ul>
            <li className="mb-2"><a href="#" className="hover:underline">Help</a></li>
            <li className="mb-2"><a href="#" className="hover:underline">Developers</a></li>
            <li className="mb-2"><a href="#" className="hover:underline">Privacy Policy</a></li>
            <li className="mb-2"><a href="#" className="hover:underline">Terms & Conditions</a></li>
          </ul>
        </div>
      </div>
      <div className="text-center mt-10">
        <p>© 2024 Nidaan. All rights reserved.</p>
      </div>
    </footer>
  );
}

export default Footer;
