import React, { useEffect, useState } from 'react';
import { getDatabase, ref, onValue } from 'firebase/database';
import { Link } from 'react-router-dom';

const Partner = () => {
  const [partners, setPartners] = useState([]);

  useEffect(() => {
    const db = getDatabase();
    const partnersRef = ref(db, 'PartnerProfile');

    onValue(partnersRef, (snapshot) => {
      const data = snapshot.val();
      if (data) {
        const partnersArray = Object.keys(data).map((key) => ({
          id: key,
          ...data[key],
        }));
        setPartners(partnersArray);
      }
    });
  }, []);

  return (
    <div className="container mx-auto mt-8">
      <h2 className="text-2xl font-bold mb-4">Partner Profiles</h2>
      <div className="overflow-x-auto">
        <table className="min-w-full bg-white border border-gray-200">
          <thead>
            <tr>
              <th className="px-4 py-2 border-b">Avatar</th>
              <th className="px-4 py-2 border-b">First Name</th>
              <th className="px-4 py-2 border-b">Last Name</th>
              <th className="px-4 py-2 border-b">Email</th>
              <th className="px-4 py-2 border-b">Phone Number</th>
              <th className="px-4 py-2 border-b">Referral Code</th>
              <th className="px-4 py-2 border-b">Status</th>
              <th className="px-4 py-2 border-b">Timestamp</th>
              <th className="px-4 py-2 border-b">Actions</th>
            </tr>
          </thead>
          <tbody>
            {partners.length > 0 ? (
              partners.map((partner) => (
                <tr key={partner.id}>
                  <td className="px-4 py-2 border-b">
                    <img
                      src={partner.avatar}
                      alt="Avatar"
                      className="w-16 h-16 rounded-full"
                    />
                  </td>
                  <td className="px-4 py-2 border-b text-blue-500 underline">
                    <Link to={`/admin-dashboard/edit-partner/${partner.id}`}>
                      {partner.firstName}
                    </Link>
                  </td>
                  <td className="px-4 py-2 border-b">{partner.lastName}</td>
                  <td className="px-4 py-2 border-b">{partner.email}</td>
                  <td className="px-4 py-2 border-b">{partner.phoneNumber}</td>
                  <td className="px-4 py-2 border-b">{partner.refCode}</td>
                  <td className="px-4 py-2 border-b">{partner.status}</td>
                  <td className="px-4 py-2 border-b">{partner.timestamp}</td>
                  <td className="px-4 py-2 border-b">
                    <button
                      onClick={() => alert(`Action for ${partner.firstName}`)}
                      className="bg-green-500 text-white py-2 px-4 rounded"
                    >
                      Action
                    </button>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="9" className="text-center py-4">
                  No partners found
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Partner;
