// HospitalData.js
import React, { useEffect, useState } from 'react';
import { db } from '../../config/firebase'; // Make sure the path is correct
import { collection, getDocs } from 'firebase/firestore';

const HospitalData = () => {
  const [hospitals, setHospitals] = useState([]);

  useEffect(() => {
    const fetchHospitals = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, 'Hospital'));
        const hospitalsData = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setHospitals(hospitalsData);
      } catch (error) {
        console.error('Error fetching hospital data:', error);
      }
    };

    fetchHospitals();
  }, []);

  return (
    <div className="p-6">
      <h2 className="text-2xl font-bold mb-4">Hospital Data</h2>
      <table className="min-w-full bg-white border border-gray-300">
        <thead>
          <tr>
            <th className="py-2 px-4 border-b">Hospital Name</th>
            <th className="py-2 px-4 border-b">Address</th>
            <th className="py-2 px-4 border-b">City</th>
            <th className="py-2 px-4 border-b">Number</th>
            <th className="py-2 px-4 border-b">Services</th>
            <th className="py-2 px-4 border-b">Ayushman</th>
            <th className="py-2 px-4 border-b">Logo</th>
            <th className="py-2 px-4 border-b">Image</th>
          </tr>
        </thead>
        <tbody>
          {hospitals.map((hospital) => (
            <tr key={hospital.id} className="hover:bg-gray-100">
              <td className="py-2 px-4 border-b">{hospital.hospitalname}</td>
              <td className="py-2 px-4 border-b">{hospital.hospitaladdress}</td>
              <td className="py-2 px-4 border-b">{hospital.hospitalcity}</td>
              <td className="py-2 px-4 border-b">{hospital.hospitalnumber}</td>
              <td className="py-2 px-4 border-b">{hospital.hospitalservices}</td>
              <td className="py-2 px-4 border-b">{hospital.hospitalayushman ? 'Yes' : 'No'}</td>
              <td className="py-2 px-4 border-b">
                <img
                  src={hospital.hospitallogo}
                  alt="Hospital Logo"
                  className="h-10 w-10 object-cover"
                />
              </td>
              <td className="py-2 px-4 border-b">
                <img
                  src={hospital.hospitalimage}
                  alt="Hospital Image"
                  className="h-10 w-10 object-cover"
                />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default HospitalData;
