import React, { useEffect, useState } from 'react';
import { getDatabase, ref, get } from 'firebase/database';
import { Link } from 'react-router-dom';

const DoctorsData = () => {
  const [doctors, setDoctors] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [filteredDoctors, setFilteredDoctors] = useState([]);
  const [selectedDoctor, setSelectedDoctor] = useState(null);

  useEffect(() => {
    const fetchDoctors = async () => {
      const db = getDatabase();
      const doctorsRef = ref(db, 'DoctorProfile');
      try {
        const snapshot = await get(doctorsRef);
        if (snapshot.exists()) {
          const doctorsList = [];
          snapshot.forEach((childSnapshot) => {
            doctorsList.push({
              id: childSnapshot.key,
              ...childSnapshot.val(),
            });
          });
          setDoctors(doctorsList);
          setFilteredDoctors(doctorsList);
        } else {
          console.log('No data available');
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchDoctors();
  }, []);

  const handleSearchQueryChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const handleSearch = () => {
    const result = doctors.filter((doctor) =>
      doctor.phoneNumber.toString().includes(searchQuery)
    );
    setFilteredDoctors(result);
  };

  const handleStatusFilter = (status) => {
    const filtered = doctors.filter((doctor) => doctor.status === status);
    setFilteredDoctors(filtered);
  };

  const handleDoctorClick = (doctor) => {
    setSelectedDoctor(doctor);
  };

  const closePopup = () => {
    setSelectedDoctor(null);
  };

  const sendWhatsAppMessages = () => {
    filteredDoctors.forEach((doctor) => {
      const message = `Hello Dr. ${doctor.firstName} ${doctor.lastName}, your current status is ${doctor.status}.`;
      const phoneNumber = `+91${doctor.phoneNumber}`; // Assuming phone numbers are in India
      const whatsappLink = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(message)}`;
      window.open(whatsappLink, '_blank');
    });
  };

  return (
    <div className="container mx-auto mt-8">
      <h2 className="text-2xl font-bold mb-4">Doctors Data</h2>

      <div className="flex items-center mb-4">
        <input
          type="text"
          value={searchQuery}
          onChange={handleSearchQueryChange}
          placeholder="Search by Phone Number"
          className="border p-2 rounded w-full mr-2"
        />
        <button
          onClick={handleSearch}
          className="bg-blue-500 text-white py-2 px-4 rounded"
        >
          Search
        </button>
      </div>

      <div className="mb-4">
        <button
          onClick={() => handleStatusFilter('Live')}
          className="bg-blue-500 text-white py-2 px-4 rounded mr-2"
        >
          Live
        </button>
        <button
          onClick={() => handleStatusFilter('Hold')}
          className="bg-red-500 text-white py-2 px-4 rounded mr-2"
        >
          Hold
        </button>
        <button
          onClick={() => handleStatusFilter('In Review')}
          className="bg-yellow-500 text-white py-2 px-4 rounded"
        >
          In Review
        </button>
        <button
          onClick={sendWhatsAppMessages}
          className="bg-green-500 text-white py-2 px-4 rounded ml-4"
        >
          Send WhatsApp Messages
        </button>
      </div>

      <div className="overflow-x-auto">
        <table className="min-w-full bg-white border border-gray-200">
          <thead>
            <tr>
              <th className="px-4 py-2 border-b">Avatar</th>
              <th className="px-4 py-2 border-b">First Name</th>
              <th className="px-4 py-2 border-b">Last Name</th>
              <th className="px-4 py-2 border-b">Timestamp</th>
              <th className="px-4 py-2 border-b">Degree</th>
              <th className="px-4 py-2 border-b">Status</th>
              <th className="px-4 py-2 border-b">Phone Number</th>
            </tr>
          </thead>
          <tbody>
            {filteredDoctors.length > 0 ? (
              filteredDoctors.map((doctor) => (
                <tr key={doctor.id}>
                  <td className="px-4 py-2 border-b">
                    <Link to={`/admin-dashboard/doctor-details/${doctor.id}`}>
                      <img
                        src={doctor.avatar}
                        alt={`${doctor.firstName} ${doctor.lastName}`}
                        className="h-12 w-12 rounded-full object-cover"
                      />
                    </Link>
                  </td>
                  <td className="px-4 py-2 border-b">{doctor.firstName}</td>
                  <td className="px-4 py-2 border-b">{doctor.lastName}</td>
                  <td className="px-4 py-2 border-b">{doctor.timestamp}</td>
                  <td className="px-4 py-2 border-b">{doctor.degree}</td>
                  <td
                    className={`px-4 py-2 border-b ${
                      doctor.status === 'Hold' ? 'text-red-500 font-bold' : ''
                    }`}
                    onClick={() => handleDoctorClick(doctor)}
                  >
                    {doctor.status}
                  </td>
                  <td className="px-4 py-2 border-b">{doctor.phoneNumber}</td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="7" className="text-center py-4">
                  No doctor profiles found.
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>

      {selectedDoctor && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-white p-8 rounded shadow-lg w-full max-w-md">
            <h3 className="text-xl font-bold mb-4">Doctor Details</h3>
            <div className="mb-4">
              <img
                src={selectedDoctor.avatar}
                alt={`${selectedDoctor.firstName} ${selectedDoctor.lastName}`}
                className="h-32 w-32 rounded-full object-cover mb-4 mx-auto"
              />
              <p>
                <strong>Name:</strong> {selectedDoctor.firstName}{' '}
                {selectedDoctor.lastName}
              </p>
              <p>
                <strong>Phone Number:</strong> {selectedDoctor.phoneNumber}
              </p>
              <p>
                <strong>Degree:</strong> {selectedDoctor.degree}
              </p>
              <p>
                <strong>Status:</strong> {selectedDoctor.status}
              </p>
              <p>
                <strong>Timestamp:</strong> {selectedDoctor.timestamp}
              </p>
            </div>
            <button
              onClick={closePopup}
              className="bg-red-500 text-white py-2 px-4 rounded mt-4"
            >
              Close
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default DoctorsData;
