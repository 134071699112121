import React, { useState } from 'react';
import { auth, provider } from '../config/firebase'; // Adjust the path if necessary
import { signInWithPopup } from "firebase/auth";
import { getDatabase, ref, get } from "firebase/database";
import { useNavigate } from 'react-router-dom';

const Login = () => {
  const [loginSuccess, setLoginSuccess] = useState(false);
  const navigate = useNavigate();

  const handleGoogleLogin = async () => {
    try {
      const result = await signInWithPopup(auth, provider);
      const user = result.user;
      console.log('User info:', user);

      // Check if user exists in the database
      checkUserInDatabase(user);
    } catch (error) {
      console.error('Error during sign-in:', error);
      setLoginSuccess(false); // Handle errors
    }
  };

  const checkUserInDatabase = async (user) => {
    try {
      const db = getDatabase();
      const userRef = ref(db, 'UserProfile/' + user.uid);
      const snapshot = await get(userRef);

      if (snapshot.exists()) {
        const userData = snapshot.val();
        console.log('User data:', userData);

        if (userData.role === "user") {
          setLoginSuccess(true);
          navigate('/'); // Redirect to the main page
        } else {
          console.log('User role is not "user"');
          // Handle other roles if needed (e.g., admin)
        }
      } else {
        console.log('User does not exist, redirecting to register page...');
        navigate('/register'); // Redirect to the registration page
      }
    } catch (error) {
      console.error('Error checking user data:', error);
    }
  };

  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-100">
      <div className="bg-white p-8 rounded-lg shadow-md w-full max-w-md">
        {loginSuccess ? (
          <div className="text-green-500 font-bold text-center">
            Login Success!
          </div>
        ) : (
          <form>
            <div className="mt-4">
              <button 
                type="button" 
                onClick={handleGoogleLogin} 
                className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline w-full">
                Login with Google
              </button>
            </div>
          </form>
        )}
      </div>
    </div>
  );
};

export default Login;
