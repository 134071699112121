// DoctorDashboard.js
import React, { useState } from 'react';
import { Link, Outlet, useLocation, useNavigate } from 'react-router-dom'; // Import useNavigate for redirection
import { FaHome, FaCalendarAlt, FaUserMd, FaUsers, FaBars, FaUserCircle,FaSignOutAlt  } from 'react-icons/fa';
import { getAuth, signOut } from 'firebase/auth'; // Import Firebase Auth methods

const DoctorDashboard = () => {
  const [isCollapsed, setIsCollapsed] = useState(false); // State for collapsing sidebar
  const location = useLocation();
  const navigate = useNavigate(); // Hook for navigation

  const isMainPage = location.pathname === '/doctor-dashboard' || location.pathname === '/doctor-dashboard/';

  // Function to handle sign-out
  const handleSignOut = () => {
    const auth = getAuth();
    signOut(auth)
      .then(() => {
        // Sign-out successful, redirect to the home page
        navigate('/');
      })
      .catch((error) => {
        // Handle any errors here
        console.error('Error signing out:', error);
      });
  };

  return (
    <div className='min-h-[calc(100vh-120px)] md:flex hidden'>
      {/* Sidebar */}
      <aside className={`bg-white dark:bg-gray-900 ${isCollapsed ? 'w-16' : 'w-60'} transition-all duration-300 min-h-full shadow-md`}>
        <div className='flex items-center justify-between p-4'>
          <span className={`${isCollapsed ? 'hidden' : 'block'} text-xl font-bold text-blue-500`}>Doctor Dashboard</span>
          <button onClick={() => setIsCollapsed(!isCollapsed)}>
            <FaBars className='text-xl text-blue-500' />
          </button>
        </div>

        {/* Navigation Links */}
        <nav className='mt-10'>
          <Link to="" className='flex items-center space-x-2 px-4 py-2 hover:bg-blue-500 hover:text-white'>
            <FaHome className='text-xl' />
            <span className={`${isCollapsed ? 'hidden' : 'block'}`}>Home</span>
          </Link>

          <Link to="appointments" className='flex items-center space-x-2 px-4 py-2 hover:bg-blue-500 hover:text-white'>
            <FaCalendarAlt className='text-xl' />
            <span className={`${isCollapsed ? 'hidden' : 'block'}`}>Appointments</span>
          </Link>

          <Link to="patients" className='flex items-center space-x-2 px-4 py-2 hover:bg-blue-500 hover:text-white'>
            <FaUsers className='text-xl' />
            <span className={`${isCollapsed ? 'hidden' : 'block'}`}>Patients</span>
          </Link>

          <Link to="profile" className='flex items-center space-x-2 px-4 py-2 hover:bg-blue-500 hover:text-white'>
            <FaUserMd className='text-xl' />
            <span className={`${isCollapsed ? 'hidden' : 'block'}`}>Profile</span>
          </Link>

          <Link to="schedule" className='flex items-center space-x-2 px-4 py-2 hover:bg-blue-500 hover:text-white'>
            <FaUserCircle className='text-xl' />
            <span className={`${isCollapsed ? 'hidden' : 'block'}`}>Schedule</span>
          </Link>

          {/* Sign Out Link */}
          <button
            onClick={handleSignOut}
            className='flex items-center space-x-2 px-4 py-2 hover:bg-blue-500 hover:text-white w-full text-left'
          >
            <FaSignOutAlt  className='text-xl' />
            <span className={`${isCollapsed ? 'hidden' : 'block'}`}>Sign Out</span>
          </button>
        </nav>
      </aside>

      {/* Main Content Area */}
      <main className='w-full h-full p-4'>
        {isMainPage ? (
          <div className='text-center text-2xl font-bold'>
            Welcome to the Doctor Dashboard
          </div>
        ) : (
          <Outlet />
        )}
      </main>
    </div>
  );
};

export default DoctorDashboard;
