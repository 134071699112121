import React from 'react';

const AdminMain = () => {
  return (
    <div className="p-6">
      <h1 className="text-2xl font-bold mb-4">Admin Main Page</h1>
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-6">
        {/* Card 1: User */}
        <div className="bg-white p-4 shadow-lg rounded-lg">
          <h2 className="text-xl font-semibold mb-2">User</h2>
          <p className="text-gray-600">Manage users and their details.</p>
          <button className="mt-4 bg-blue-500 text-white py-2 px-4 rounded">
            View Users
          </button>
        </div>

        {/* Card 2: Hospital */}
        <div className="bg-white p-4 shadow-lg rounded-lg">
          <h2 className="text-xl font-semibold mb-2">Hospital</h2>
          <p className="text-gray-600">Manage hospitals and their profiles.</p>
          <button className="mt-4 bg-green-500 text-white py-2 px-4 rounded">
            View Hospitals
          </button>
        </div>

        {/* Card 3: Doctor */}
        <div className="bg-white p-4 shadow-lg rounded-lg">
          <h2 className="text-xl font-semibold mb-2">Doctor</h2>
          <p className="text-gray-600">Manage doctor profiles and details.</p>
          <button className="mt-4 bg-yellow-500 text-white py-2 px-4 rounded">
            View Doctors
          </button>
        </div>

        {/* Card 4: Health Card */}
        <div className="bg-white p-4 shadow-lg rounded-lg">
          <h2 className="text-xl font-semibold mb-2">Health Card</h2>
          <p className="text-gray-600">Manage health cards and subscriptions.</p>
          <button className="mt-4 bg-red-500 text-white py-2 px-4 rounded">
            View Health Cards
          </button>
        </div>
      </div>
    </div>
  );
};

export default AdminMain;
