import React, { useState, useEffect } from 'react';
import Logo from './Logo';
import { FaRegCircleUser,FaBell } from "react-icons/fa6";
import { Link, useNavigate } from 'react-router-dom';
import { auth } from '../config/firebase';
import { signOut, onAuthStateChanged } from "firebase/auth";
import { getDatabase, ref, get } from "firebase/database";

const Header = () => {
  const [user, setUser] = useState(null);
  const [showDropdown, setShowDropdown] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      setUser(currentUser);
    });
    return () => unsubscribe();
  }, []);

  const handleSignOut = () => {
    signOut(auth).then(() => {
      console.log('User signed out');
      setUser(null);
      navigate('/login');
    }).catch((error) => {
      console.error('Error signing out:', error);
    });
  };

  const handleUserIconClick = async () => {
    if (user) {
      const db = getDatabase();
      const userRef = ref(db, 'UserProfile/' + user.uid);

      try {
        const snapshot = await get(userRef);
        if (snapshot.exists()) {
          const userData = snapshot.val();
          if (userData.role === 'admin' || userData.role === 'Manager') {
            navigate('/admin-dashboard');
          } else {
            navigate('/user-dashboard');
          }
        } else {
          console.error('No user data found');
        }
      } catch (error) {
        console.error('Error checking user role:', error);
      }
    } else {
      navigate('/login');
    }
  };

  return (
    <header className='h-16 shadow-md bg-white'>
      <div className='h-full container mx-auto flex items-center px-4 justify-between'>
        <div>
          <Link to={"/"}>
            <Logo w={180} h={50} />
          </Link>
        </div>
        <div className="flex gap-8">
          <Link to="/find-doctors" className="hover:underline">Find Doctors</Link>
          <Link to="/video-consult" className="hover:underline">Video Consult</Link>
          <Link to="/surgeries" className="hover:underline">Surgeries</Link>
          <div 
            className="relative" 
            onMouseEnter={() => setShowDropdown('forCorporates')} 
            onMouseLeave={() => setShowDropdown(null)}
          >
            <button className="hover:underline">For Corporates</button>
            {showDropdown === 'forCorporates' && (
              <div className="absolute bg-white shadow-md p-4">
                <Link to="/corporate-health" className="block hover:underline mb-2">Health & Wellness Plans</Link>
                <Link to="/group-insurance" className="block hover:underline">Group Insurance</Link>
              </div>
            )}
          </div>
          <div 
            className="relative" 
            onMouseEnter={() => setShowDropdown('forProviders')} 
            onMouseLeave={() => setShowDropdown(null)}
          >
            <button className="hover:underline">For Providers</button>
            {showDropdown === 'forProviders' && (
              <div className="absolute bg-white shadow-md p-4">
                <Link to="/for-doctor" className="block hover:underline mb-2">For Doctors</Link>
                <Link to="/provider-insights" className="block hover:underline">Provider Insights</Link>
              </div>
            )}
          </div>
          <div 
            className="relative" 
            onMouseEnter={() => setShowDropdown('securityHelp')} 
            onMouseLeave={() => setShowDropdown(null)}
          >
            <button className="hover:underline">Security & Help</button>
            {showDropdown === 'securityHelp' && (
              <div className="absolute bg-white shadow-md p-4">
                <Link to="/help" className="block hover:underline mb-2">Help Center</Link>
                <Link to="/privacy-policy" className="block hover:underline">Privacy Policy</Link>
              </div>
            )}
          </div>
        </div>
        <div className='flex items-center gap-4'>
          <div 
            className='text-3xl cursor-pointer relative' 
            onClick={handleUserIconClick}
          >
            <FaRegCircleUser />
          </div>
          <div>
            {user ? (
              <button 
                onClick={handleSignOut} 
                className='px-3 py-1 rounded-full text-white bg-red-600 hover:bg-red-700'>
                Sign Out
              </button>
            ) : (
              <Link to={"login"} className='px-3 py-1 rounded-full text-white bg-red-600 hover:bg-red-700'>
                Login
              </Link>
            )}
          </div>
          
          <div>
            <FaBell/>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
