// App.js
import logo from './logo.svg';
import './App.css';
import { Outlet, useLocation } from 'react-router-dom'; // Importing useLocation
import Header from './components/Header';
import Footer from './components/Footer';

function App() {
  const location = useLocation();

  // Define the paths where you want to hide the Header and Footer
  const hideHeaderFooter = location.pathname.startsWith('/doctor-dashboard');

  return (
    <>
      {/* Conditionally render Header and Footer based on the location */}
      {!hideHeaderFooter && <Header />}
      <main className='min-h-[calc(100vh-100px)]'>
        <Outlet />
      </main>
      {!hideHeaderFooter && <Footer />}
    </>
  );
}

export default App;
